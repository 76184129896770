import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, Node } from "@contentful/rich-text-types";
import { Asset } from "contentful";
import { PlayIcon } from "icons";
import Image from "next/image";
import ReactPlayer from "react-player";
import { IArticleFields, IJobsV3Fields } from "types";
import { mergeClasses } from "utils";

export enum CustomContentTypes {
  TextCenter = "textCenter",
  ImageGrid = "imageGrid",
}

const renderNodeMap = {
  [BLOCKS.HEADING_1]: (_: any, children: any) => (
    <h1 className="news-h1">{children}</h1>
  ),
  [BLOCKS.HEADING_2]: (_: any, children: any) => (
    <h2 className="news-h2">{children}</h2>
  ),
  [BLOCKS.HEADING_3]: (_: any, children: any) => (
    <h3 className="news-h3">{children}</h3>
  ),
  [BLOCKS.HEADING_4]: (_: any, children: any) => (
    <h4 className="news-h4">{children}</h4>
  ),
  [BLOCKS.HEADING_5]: (_: any, children: any) => (
    <h5 className="news-h5">{children}</h5>
  ),
  [BLOCKS.HEADING_6]: (_: any, children: any) => (
    <h6 className="news-h6">{children}</h6>
  ),
  [BLOCKS.LIST_ITEM]: (_: any, children: any) => {
    return <li className="news-li">{children}</li>;
  },
  [BLOCKS.OL_LIST]: (_: any, children: any) => {
    return <ol className="news-ol">{children}</ol>;
  },
  [BLOCKS.UL_LIST]: (_: any, children: any) => {
    return <ul className="news-ul">{children}</ul>;
  },
  [BLOCKS.PARAGRAPH]: (_: any, children: any) => {
    return <p className="news-p">{children}</p>;
  },
  [INLINES.HYPERLINK]: ({ data: { uri } }: any, children: any) => {
    return (
      <a className="news-link" href={uri} rel="noreferrer" target="_blank">
        {children}
      </a>
    );
  },
};

const renderEmbeddedAsset = ({ data }: Node) => {
  const {
    target: {
      fields: { file },
    },
  } = data ?? {};
  const { contentType } = file;

  switch (true) {
    case contentType.includes("video/"):
      return (
        <ReactPlayer
          controls
          height="100%"
          light
          muted
          playIcon={
            <PlayIcon className="text-white w-[56px!important] h-[56px!important]" />
          }
          playing
          playsinline
          style={{
            borderRadius: "32px",
            overflow: "hidden",
            position: "relative",
            maxWidth: "800px",
            maxHeight: "450px",
            margin: "0 auto",
          }}
          url={`https:${file.url}`}
          width="100%"
        />
      );
    case contentType.includes("image/"):
      return (
        <div className={`flex justify-center overflow-hidden relative mb-8`}>
          <span className="rounded-[32px] block overflow-hidden">
            <Image
              alt=""
              height={file.details.image.height}
              src={`https:${file.url}`}
              width={file.details.image.width}
            />
          </span>
        </div>
      );
    default:
      return "Not supported file format!";
  }
};

const renderEmbeddedAssetBlock = ({ data }: Node) => {
  const {
    target: {
      fields,
      sys: {
        contentType: {
          sys: { id: contentTypeId },
        },
      },
    },
  } = data ?? {};
  switch (contentTypeId) {
    case CustomContentTypes.TextCenter:
      return (
        <div className="text-center">
          {documentToReactComponents(fields.content, {
            renderNode: renderNodeMap,
          })}
        </div>
      );
    case CustomContentTypes.ImageGrid:
      return (
        <div className="grid gap-[30px] lg:grid-cols-2">
          {fields.images.map((img: Asset) => (
            <span
              className={mergeClasses(
                "relative overflow-hidden rounded-[32px]"
              )}
              key={img.sys.id}
            >
              <Image
                alt=""
                height={img.fields.file.details.image?.height ?? 0}
                src={`https:${img.fields.file.url}`}
                width={img.fields.file.details.image?.width ?? 0}
              />
            </span>
          ))}
        </div>
      );
    default:
      return null;
  }
};

export const renderNewsContent = (content: IArticleFields["content"]) => {
  return documentToReactComponents(content, {
    renderNode: {
      ...renderNodeMap,
      [BLOCKS.EMBEDDED_ASSET]: renderEmbeddedAsset,
      [BLOCKS.EMBEDDED_ENTRY]: renderEmbeddedAssetBlock,
    },
  });
};
export const renderJobsContent = (content: IJobsV3Fields["jobDescription"]) => {
  return documentToReactComponents(content, {
    renderNode: {
      ...renderNodeMap,
      [BLOCKS.EMBEDDED_ASSET]: renderEmbeddedAsset,
      [BLOCKS.EMBEDDED_ENTRY]: renderEmbeddedAssetBlock,
    },
  });
};
