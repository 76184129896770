import { IBuildSlotFields } from "types";
import { mergeClasses } from "utils";

const OrderItemBlack: React.FC<IBuildSlotFields> = ({
  number,
  location,
  status,
}) => (
  <li className="text-white lg:flex lg:justify-between lg:text-left text-center items-center lg:py-4 py-2 lg:border-b-[1px] lg:border-gray-400 lg:border-opacity-50">
    <p
      className={mergeClasses(
        "lg:text-[40px] text-[32px] font-medium lg:leading-[47px] leading-[37px]",
        location ? "" : "text-accent"
      )}
    >
      Chassis #{number}
    </p>
    <div className="flex items-center justify-center">
      {location ? (
        <div className="lg:flex lg:flex-col lg:items-end">
          <p className="lg:text-[20px] text-[18px] font-normal leading-[24px] lg:text-right pb-2">
            {status} {location.split(",", 1)}
          </p>
          <p className="lg:text-[20px] text-[18px] font-normal text-gray-400 leading-[18px]">
            {location.split(",")[1]}
            {location.split(",")[2] ? "," : ""}
            {location.split(",")[2]}
          </p>
        </div>
      ) : (
        <p className="text-[16px] leading-[24px] text-accent font-semibold">
          {status.split(" ")[1]}
        </p>
      )}
    </div>
  </li>
);

export default OrderItemBlack;
