import { AngleIcon } from "icons";
import parsePhoneNumber, {
  CountryCallingCode,
  CountryCode,
  getCountries,
  getCountryCallingCode,
  NationalNumber,
} from "libphonenumber-js/mobile";
import { useEffect, useState } from "react";
import { FieldError, FieldErrors } from "react-hook-form";
import Select from "react-select";
import { mergeClasses } from "utils";

interface PrefixOption {
  value: CountryCallingCode;
  label: string;
  country: CountryCode;
  index: number;
}

const prefixOptions: PrefixOption[] = getCountries().map((country, index) => {
  const countryPrefix = getCountryCallingCode(country);
  return {
    value: countryPrefix,
    label: `${country} (+${countryPrefix})`,
    country,
    index,
  };
});

const DropdownIndicator = () => (
  <span className="px-2">
    <AngleIcon className="!w-2" />
  </span>
);

interface PhoneNumberInputProps {
  name: string;
  label?: string;
  initialValue?: string;
  onChange: (phoneNumber: string) => void;
  menuPortalTarget?: React.RefObject<HTMLElement>;
  defaultPrefix?: string;
  className?: string;
  errors?: FieldErrors;
  isRequired?: boolean;
  placeholder?: string;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  name,
  label,
  initialValue = "",
  onChange,
  menuPortalTarget,
  defaultPrefix = "",
  className = "",
  errors,
  isRequired = false,
  placeholder,
}) => {
  const [prefix, setPrefix] = useState<CountryCallingCode>(defaultPrefix);
  const [prefixIndex, setPrefixIndex] = useState(
    prefixOptions.findIndex(({ country }) => country === defaultPrefix) ?? 0
  );
  const [number, setNumber] = useState<NationalNumber>("");

  const error: FieldError = errors && errors[name];

  useEffect(() => {
    const parsedNumber = parsePhoneNumber(initialValue);
    if (parsedNumber && typeof parsedNumber !== "string") {
      if (parsedNumber.nationalNumber !== number) {
        setNumber(parsedNumber.nationalNumber);
      }
      if (parsedNumber.countryCallingCode !== prefix) {
        setPrefixIndex(
          prefixOptions.findIndex(
            ({ value }) => value === parsedNumber.countryCallingCode
          )
        );
        setPrefix(parsedNumber.countryCallingCode);
      }
    }
  }, [initialValue]);

  const handleNumberChange = (value: string) => {
    setNumber(value as NationalNumber);
    const mergedNumber = `+${prefix}${value}`;
    onChange(value === "" ? "" : mergedNumber);
  };

  const changePrefixChange = (index: number) => {
    const newPrefix = prefixOptions[index].value;

    setPrefixIndex(index);
    setPrefix(newPrefix);
    const mergedNumber = `+${newPrefix}${number}`;
    onChange(number === "" ? "" : mergedNumber);
  };

  return (
    <div className={className}>
      <label htmlFor={name}>
        {label && (
          <p className="mb-2 text-base">
            {label}
            {isRequired && <span className="text-red-600">*</span>}
          </p>
        )}
      </label>
      <div className="grid grid-cols-[150px_1fr] sm:grid-cols-[160px_1fr] gap-2">
        <Select
          className={mergeClasses(
            "outline-0 border flex items-center transition-all text-base",
            error ? "border-red-500" : "border-gray-300"
          )}
          classNamePrefix={`${name}PhoneNumberPrefix`}
          components={{
            IndicatorSeparator: undefined,
            DropdownIndicator,
          }}
          menuPortalTarget={menuPortalTarget && menuPortalTarget.current}
          name={`${name}-numberPrefix`}
          onChange={({ index }: any) => changePrefixChange(index)}
          options={prefixOptions}
          styles={{
            option: (provided, { data }) => ({
              ...provided,
              cursor: "pointer",
              backgroundColor:
                data.index === prefixIndex ? "#EA9B3B" : "transparent",
              color: data.index === prefixIndex ? "#fff" : "#000",
            }),
            control: () => ({
              display: "flex",
              width: "100%",
              flexDirection: "row",
              paddingLeft: "12px",
              paddingRight: "12px",
            }),
            multiValue: () => ({
              display: "none",
            }),
            menuPortal: (provided) => ({
              ...provided,
              width: 200,
              zIndex: 999,
            }),
          }}
          value={prefixOptions.find(({ index }) => index === prefixIndex ?? "")}
        />
        <input
          autoComplete="off"
          className={mergeClasses(
            "p-4 appearance-none border w-full transition-all text-base",
            error ? "border-red-500" : "border-gray-300"
          )}
          id={name}
          name={name}
          onChange={async ({ target }) => handleNumberChange(target.value)}
          placeholder={placeholder}
          type="number"
          value={(number as string) ?? ""}
        />
      </div>
      {error ? (
        <p className="text-red-600 text-[13px]">{error.message}</p>
      ) : null}
    </div>
  );
};

export default PhoneNumberInput;
