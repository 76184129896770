import { ReactNode } from "react";
import Modal from "../Modal";

interface SaleAgreementsModalProps {
  isModalOpen: boolean;
  setModalClose: () => void;
  agreements: ReactNode;
  title: string;
}

const SaleAgreementModal: React.FC<SaleAgreementsModalProps> = ({
  agreements,
  isModalOpen,
  setModalClose,
  title,
}) => {
  return (
    <Modal
      className="max-w-[888px] lg:h-[486px] h-[868px]"
      closeActions={setModalClose}
      isModalOpen={isModalOpen}
    >
      <div className="max-w-[728px]">
        <h3 className="text-2xl">{title}</h3>
        <div className="relative mt-6">
          <div className=" lg:p-4 text-[16px] max-h-[680px] lg:max-h-[278px] overflow-x-scroll lg:border">
            <div className="lg:orderFormTerms space-y-6">{agreements}</div>
            <div
              className="h-[26px] w-full absolute bottom-[1px] border-[#DDDDDC] left-[1px] right-[1px] bg-white max-w-[800px]"
              style={{
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SaleAgreementModal;
