import { Breadcrumbs } from "components";
import Image from "next/image";
import { IArticleV3Fields, IDeliverySectionFields } from "types";
import { renderNewsContent } from "utils";
import { DeliverySection } from "../index";

const SingleNewsSection: React.FC<{
  news: IArticleV3Fields;
  delivery: IDeliverySectionFields[];
}> = ({ news, delivery }) => {
  return (
    <section className="bg-white">
      <div className="container">
        <Breadcrumbs className="mb-6 lg:mb-16" slug={news.title} />
        <div className="pb-section">
          {!news.videourl ? (
            <div className="flex justify-center mb-16 rounded-[32px] overflow-hidden relative w-full h-[580px] news-container">
              {news.mainImage.fields.file.url ? (
                <Image
                  alt=""
                  layout="fill"
                  objectFit="cover"
                  src={`https:${news.mainImage.fields.file.url}`}
                />
              ) : null}
            </div>
          ) : (
            <div className="flex justify-center mb-16 overflow-hidden relative w-full h-[580px]">
              <iframe
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="rounded-[32px]"
                height="auto"
                src={`https://www.youtube.com/embed/${news.videourl}`}
                title="Jetson YouTube video player"
                width="1140px"
              ></iframe>
            </div>
          )}
          <div className="news-container">
            <h1 className="text-[64px] leading-[1.175] text-center mb-8">
              {news.title}
            </h1>
            <div className="flex flex-row space-x-[16px] lg:text-[18px] text-[14px] items-center pt-6 lg:pt-0 pb-6 justify-center">
              <p className="uppercase text-accent leading-[15px]">
                {news.author}
              </p>
              <span className="">&middot;</span>
              <p>{news.dateOfPublish}</p>
            </div>
            <div className="whitespace-pre-wrap lg:mx-[7.5%]">
              {renderNewsContent(news.content)}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#F4F4F1]">
        <DeliverySection deliverySection={delivery} />
      </div>
    </section>
  );
};

export default SingleNewsSection;
