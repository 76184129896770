import { PaymentMethodsEnum } from "@types";
import { mergeClasses } from "utils";

interface PaymentMethod {
  name: PaymentMethodsEnum;
  title: string;
  description?: string;
}

const paymentMethods: PaymentMethod[] = [
  {
    name: PaymentMethodsEnum.Card,
    title: "Down payment with credit card",
  },
  {
    name: PaymentMethodsEnum.WireTransfer,
    title: "Request invoice and bank transfer",
  },
];

const PaymentMethodItem: React.FC<
  PaymentMethod & {
    onClick: () => void;
    checked: boolean;
  }
> = ({ title, description, onClick, checked }) => {
  return (
    <li
      className={mergeClasses(
        "rounded-t-[10px] lg:p-6 p-4 cursor-pointer transition-all border-t border-l border-r",
        checked ? "bg-[#F4F4F1]" : "hover:bg-[#fdfdfd]"
      )}
      onClick={onClick}
    >
      <div className="flex items-center">
        <p className="font-medium leading-5 transition-all">{title}</p>
      </div>
      {description && (
        <p
          className={mergeClasses(
            "text-sm leading-4 transition-all overflow-hidden",
            checked ? "mt-4 max-h-28" : "max-h-0"
          )}
        >
          {description}
        </p>
      )}
    </li>
  );
};

interface PaymentMethodSelectProps {
  onChange: (newValue: PaymentMethodsEnum) => void;
  value: PaymentMethodsEnum;
}

const PaymentMethodSelect: React.FC<PaymentMethodSelectProps> = ({
  value,
  onChange,
}) => {
  return (
    <ul className="flex flex-row gap-3 border-[#F4F4F1]">
      {paymentMethods.map((method, index) => (
        <PaymentMethodItem
          checked={value === method.name}
          onClick={() => onChange(method.name)}
          {...method}
          key={`paymentMethod_${index}`}
        />
      ))}
    </ul>
  );
};

export default PaymentMethodSelect;
