import { Breadcrumbs } from "components";
import { useEffect, useState } from "react";

const CareerHeaderSection = () => {
  const [isMobile, setIsMobile] = useState(false);
  const MOBILE_BREAKPOINT = 1023;

  const handleResize = () => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className="bg-white">
      <Breadcrumbs className="mb-6 lg:mb-16 container" />
      <div className="flex lg:flex-row flex-col-reverse container py-section items-center gap-16">
        <div className="max-w-[636px]">
          <h3 className="lg:text-[56px] text-4xl lg:text-left text-center leading-[66px] mb-8">
            We hire great people and we help them grow
          </h3>
          <p className="text-lg">
            Jetson is a Pioneering EVTOL company with a mission to change the
            way we travel. We aim to make the skies available for everyone with
            our safe personal electric aerial vehicle.
          </p>
        </div>
        <div>
          <div className="relative flex justify-center bg-black lg:rounded-[80px] rounded-[48px] overflow-hidden h-[548px] max-w-[978px]">
            <video
              autoPlay
              className="object-cover opacity-60 w-full lg:rounded-[80px] rounded-[48px]"
              loop
              muted
              playsInline
            >
              <source
                src={
                  isMobile
                    ? "assets/videos/jetson_one_background_mobile.mp4"
                    : "assets/videos/jetson_one_background_desktop.mp4"
                }
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareerHeaderSection;
