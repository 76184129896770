export * from "./contentfulContentRenderer";
export * from "./validation";
import { useEffect, useState } from "react";

export const mergeClasses = (...classes: string[]): string =>
  classes
    .filter(Boolean)
    .map((c) => (typeof c === "object" ? c : c))
    .join(" ");

export const truncateText = (
  text: string,
  size = 15,
  fileExtension = false
) => {
  const startLength = fileExtension ? size - 10 : size - 3;
  if (text?.length > size) {
    return `${text.substring(0, startLength)}...${
      fileExtension ? text.substring(text.length - 7, text.length) : ""
    }`;
  } else {
    return text;
  }
};

export const useCheckMobileScreen = () => {
  const defaultWidth = typeof window !== "undefined" ? window.innerWidth : 0;
  const [width, setWidth] = useState(defaultWidth);
  const handleWindowSizeChange = () => {
    if (window) {
      setWidth(window.innerWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width <= 1023;
};
