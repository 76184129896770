import { Button } from "components";
import { ArrowIcon } from "icons";
import Image from "next/image";
import Link from "next/link";
import { IJobsV3Fields } from "types";

const ListItem: React.FC<{
  jobPosition: string;
  slug: string;
}> = ({ jobPosition, slug }) => {
  return (
    <div className="border p-10 rounded-[40px] flex flex-col justify-between flex-1">
      <div>
        <p className="text-accent uppercase leading-4 pb-2">We’re hiring!</p>
        <h3 className="text-[32px] leading-[38px] font-medium pb-8">
          {jobPosition}
        </h3>
      </div>
      <div>
        <Link href={`/careers/${slug}`} passHref>
          <a>
            <Button
              className="!p-0"
              label="Apply"
              rightIcon={ArrowIcon}
              rightIconClassName="text-accent ml-7"
            />
          </a>
        </Link>
      </div>
    </div>
  );
};

const NoJobOffers = () => {
  return (
    <div className="py-section container lg:text-5xl text-2xl text-center">
      <div>
        <Image
          alt="jetson one"
          height={60}
          priority
          src="/assets/images/jetson_logo.png"
          width={200}
        />
      </div>
      <h3 className="pb-10 pt-10">
        We are not currently looking for employees for any position.
      </h3>
      <h3 className="text-accent">Check back in a while!</h3>
    </div>
  );
};

const JobsListSection: React.FC<{ jobs: IJobsV3Fields[] }> = ({ jobs }) => {
  return (
    <section className="bg-white">
      {jobs?.length ? (
        <div className="container py-section">
          <ul
            className="flex lg:flex-row flex-col gap-10 flex-wrap items-center justify-center flex flex-col items-center
"
          >
            {jobs.map((job, index) => {
              return (
                <li
                  className="flex lg:w-[390px] lg:h-[274px] w-full"
                  key={`jobs_${index}`}
                >
                  <ListItem
                    jobPosition={job.jobPosition}
                    slug={job.slug}
                    {...jobs}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <NoJobOffers />
      )}
    </section>
  );
};
export default JobsListSection;
