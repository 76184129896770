import { yupResolver } from "@hookform/resolvers/yup";
import { Value } from "@sentry/utils/types/buildPolyfills/types";
import { Button, Checkbox, Dropdown, Loader, TextField } from "components";
import { ArrowIcon } from "icons";
import Link from "next/link";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import countryList from "react-select-country-list";
import { usaStates } from "typed-usa-states";
import { PaymentMethodsEnum } from "types";
import { mergeClasses } from "utils";
import { wireTransferFormSchema } from "./WireTransferFormSchema";

export enum PaymentMethodFormEnum {
  PaymentMethod = "paymentMethod",
  FullName = "name",
  Street = "street",
  ZipCode = "zipCode",
  State = "state",
  Country = "country",
  CompanyName = "companyName",
  VatId = "vatId",
  TermsAccepted = "termsAccepted",
  Email = "email",
  City = "city",
}

interface WireTransferValues {
  [PaymentMethodFormEnum.TermsAccepted]: boolean;
  [PaymentMethodFormEnum.FullName]: string;
  [PaymentMethodFormEnum.ZipCode]: string;
  [PaymentMethodFormEnum.Street]: string;
  [PaymentMethodFormEnum.CompanyName]: string;
  [PaymentMethodFormEnum.State]: Value;
  [PaymentMethodFormEnum.Country]: string;
  [PaymentMethodFormEnum.VatId]: string;
  [PaymentMethodFormEnum.Email]: string;
  [PaymentMethodFormEnum.City]: string;
  [PaymentMethodFormEnum.PaymentMethod]: PaymentMethodsEnum;
}
const WireTransferForm: React.FC<{
  setPaymentCompleted: () => void;
  onModalOpen: () => void;
}> = ({ setPaymentCompleted, onModalOpen }) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm<WireTransferValues>({
    resolver: yupResolver(wireTransferFormSchema()),
  });
  const [loading, setLoading] = useState(false);

  const [countrySelectValue, stateSelectValue, termsAgreementValue] = watch([
    PaymentMethodFormEnum.Country,
    PaymentMethodFormEnum.State,
    PaymentMethodFormEnum.TermsAccepted,
  ]);

  const countryOptions = useMemo(() => countryList().getData(), []);
  const statesOptions = useMemo(
    () =>
      usaStates.map(({ name }) => ({
        value: name,
        label: name,
      })),
    []
  );

  const onSubmit = async (values: WireTransferValues) => {
    try {
      setLoading(true);
      const res = await fetch("/api/order", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      if (res.status === 200) {
        setPaymentCompleted();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="request-invoice-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-2">
        <TextField
          errors={errors}
          {...register(PaymentMethodFormEnum.FullName)}
          isRequired
          label="Full name"
          name={PaymentMethodFormEnum.FullName}
          placeholder="First and last name"
        />
        <TextField
          errors={errors}
          {...register(PaymentMethodFormEnum.Email)}
          isRequired
          label="Email"
          name={PaymentMethodFormEnum.Email}
          placeholder="Email"
        />
        <TextField
          errors={errors}
          {...register(PaymentMethodFormEnum.CompanyName)}
          label="Company name - optional"
          name={PaymentMethodFormEnum.CompanyName}
          placeholder="Company name"
        />
        <TextField
          errors={errors}
          {...register(PaymentMethodFormEnum.VatId)}
          label="VAT ID - optional"
          name={PaymentMethodFormEnum.VatId}
          placeholder="VAT ID"
        />
        <Dropdown
          {...register(PaymentMethodFormEnum.Country)}
          errors={errors}
          label="Country or region"
          onChange={({ label }) =>
            setValue(PaymentMethodFormEnum.Country, label)
          }
          options={countryOptions}
          placeholder="Country or region"
          value={
            countrySelectValue
              ? countryOptions.find((opt) => opt.label === countrySelectValue)
              : undefined
          }
        />
        {countrySelectValue === "United States" && (
          <Dropdown
            {...register(PaymentMethodFormEnum.State)}
            errors={errors}
            isRequired
            label="State"
            onChange={({ value }) =>
              setValue(PaymentMethodFormEnum.State, value)
            }
            options={statesOptions}
            placeholder="State"
            value={
              stateSelectValue
                ? statesOptions.find((opt) => opt.label === stateSelectValue)
                : undefined
            }
          />
        )}
        <TextField
          errors={errors}
          {...register(PaymentMethodFormEnum.Street)}
          isRequired
          label="Address"
          name={PaymentMethodFormEnum.Street}
          placeholder="Street address"
        />
        <div className="lg:grid grid-cols-2 gap-8">
          <TextField
            errors={errors}
            {...register(PaymentMethodFormEnum.City)}
            isRequired
            label="City"
            name={PaymentMethodFormEnum.City}
            placeholder="City"
          />
          <TextField
            errors={errors}
            {...register(PaymentMethodFormEnum.ZipCode)}
            isRequired
            label="Postal code"
            name={PaymentMethodFormEnum.ZipCode}
            placeholder="Postal code"
          />
        </div>
      </div>
      <div className="mt-6">
        <Checkbox
          checked={termsAgreementValue}
          errors={errors}
          label={
            <>
              I agree to the&nbsp;
              <span
                className="text-accent hover:underline cursor-pointer"
                onClick={onModalOpen}
              >
                Jetson One Pre-Order Sale Agreement
              </span>
              &nbsp;and&nbsp;
              <Link href="/privacy-policy" passHref>
                <a
                  className="text-accent hover:underline"
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </Link>
              .
            </>
          }
          name={PaymentMethodFormEnum.TermsAccepted}
          onChange={(checked) =>
            setValue(PaymentMethodFormEnum.TermsAccepted, checked)
          }
        />
        <Button
          className={mergeClasses(
            "button-primary h-14 relative transition-all lg:mx-0 mx-auto mt-10 w-full lg:w-auto",
            loading
              ? "!pr-16 cursor-not-allowed opacity-70 pointer-events-none"
              : "pr-8"
          )}
          label="Request Invoice"
          rightIcon={
            loading ? () => <Loader className="left-auto right-3" /> : ArrowIcon
          }
          rightIconClassName="text-accent ml-5 !w-6 !h-6"
          type="submit"
        />
      </div>
    </form>
  );
};

export default WireTransferForm;
