import { Elements } from "@stripe/react-stripe-js";
import {
  Appearance,
  loadStripe,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { Button, TextField } from "components";
import { ArrowIcon } from "icons";
import { useRouter } from "next/router";
import { FormEvent, useState } from "react";
import { useFormContext } from "react-hook-form";
import { mergeClasses } from "utils";
import CheckoutForm from "../CheckoutForm";

const CardPaymentMethod: React.FC<{
  setPaymentCompleted: () => void;
  onModalOpen: () => void;
}> = ({ setPaymentCompleted, onModalOpen }) => {
  const { query } = useRouter();
  const stripePromise = loadStripe(
    process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string
  );
  const {
    register,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext();
  const [clientSecret, setClientSecret] = useState(
    typeof query.paymentSecret === "string" ? query.paymentSecret : ""
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isEmailProvided, setIsEmailProvided] = useState(!!query.paymentSecret);
  const customerEmail = watch("customerEmail");
  const body = {
    customerEmail,
  };
  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = await trigger("customerEmail");
    if (!isValid) {
      return;
    }
    setIsLoading(true);
    await fetch("/api/payment/payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));

    setIsLoading(false);
    setIsEmailProvided(true);
  };

  const appearance: Appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#000",
      colorText: "#000",
    },
  };

  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
    locale: "en",
    loader: "never",
  };
  return isEmailProvided ? (
    <div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            onModalOpen={onModalOpen}
            setPaymentCompleted={setPaymentCompleted}
          />
        </Elements>
      )}
    </div>
  ) : (
    <div>
      <form className="max-w-[600px] mx-auto" onSubmit={onSubmit}>
        <div className="flex lg:flex-row flex-col gap-4 my-10">
          <div className="w-full">
            <TextField
              {...register("customerEmail")}
              errors={errors}
              placeholder="Please enter your email address"
            />
          </div>
          <div>
            <Button
              className={mergeClasses(
                "button button-primary mt-2 w-full lg:w-auto",
                isLoading ? "animate-pulse" : ""
              )}
              disabled={isLoading}
              label="start"
              rightIcon={ArrowIcon}
              rightIconClassName="text-accent ml-4"
              type="submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
};
export default CardPaymentMethod;
