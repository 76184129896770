import { AngleIcon } from "icons";
import { FieldErrors } from "react-hook-form";
import Select from "react-select";
import { mergeClasses } from "utils";

type Value = string | number | boolean | null;
type Option = { value: Value; label: string };

interface DropdownProps {
  value?: Option;
  name: string;
  label?: string;
  options: Option[];
  isSearchable?: boolean;
  isRequired?: boolean;
  className?: string;
  onChange: (value: Option) => void;
  errors?: FieldErrors;
  placeholder?: string;
}

const DropdownIndicator = () => (
  <span className="px-2">
    <AngleIcon className="!w-2" />
  </span>
);

const Dropdown: React.FC<DropdownProps> = ({
  isSearchable = true,
  isRequired,
  className = "",
  label,
  name,
  options,
  value,
  onChange,
  errors,
  placeholder = " ",
}) => {
  const error = errors && errors[name];

  return (
    <div className={mergeClasses("flex flex-col", className)}>
      <label className="relative block">
        {label && (
          <p className="text-base">
            {label}
            {isRequired && <span className="text-red-600">*</span>}
          </p>
        )}
        <Select
          className={mergeClasses(
            "outline-0 border transition-all bg-white",
            error ? "border-red-500" : "border-gray-300"
          )}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator,
          }}
          inputId={name}
          isSearchable={isSearchable}
          name={name}
          onChange={onChange as any}
          options={options as any}
          placeholder={placeholder}
          styles={{
            option: (provided, { isSelected, isFocused }) => ({
              ...provided,
              cursor: "pointer",
              backgroundColor: isSelected
                ? "#EA9B3B"
                : isFocused
                ? "#eee"
                : "transparent",
              color: isSelected ? "#fff" : "#000",
            }),
            container: (provided) => ({
              ...provided,
              display: "block",
              marginTop: "10px",
              transition: "all 150ms",
            }),
            control: () => ({
              display: "flex",
              width: "100%",
              flexDirection: "row",
              paddingLeft: "6px",
              paddingRight: "6px",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontSize: "16px",
            }),
            input: (provided) => ({
              ...provided,
            }),
          }}
          value={value}
        />
      </label>
      {error && <p className="text-red-600 text-[13px]">{error.message}</p>}
    </div>
  );
};

export default Dropdown;
