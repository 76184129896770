import { CheckIcon } from "icons";
import { ChangeEvent } from "react";
import { FieldError, FieldErrors } from "react-hook-form";
import { mergeClasses } from "utils";

interface CheckboxProps {
  name: string;
  checked?: boolean;
  label?: string | React.ReactNode;
  onChange?: (checked: boolean) => void;
  className?: string;
  errors?: FieldErrors;
  errorClassname?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  onChange,
  name,
  label,
  className = "",
  errors,
  errorClassname,
}) => {
  const error: FieldError = errors && errors[name];

  return (
    <div className={className}>
      <div className="flex items-start">
        <label
          className={mergeClasses(
            "border-[1px] min-w-[26px] min-h-[26px] cursor-pointer mr-4 relative",
            error ? "border-red-600" : "border-accent"
          )}
        >
          <input
            checked={checked ?? false}
            className="opacity-0"
            id={name}
            name={name}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChange && onChange(e.target.checked)
            }
            type="checkbox"
          />
          <div
            className={mergeClasses(
              "bg-accent w-[18px] h-[18px] absolute top-0 bottom-0 left-0 right-0 m-auto",
              checked ? "animate-grow" : "scale-0"
            )}
          />
          {checked && (
            <CheckIcon className="!h-4 !w-4 p-1 text-white absolute top-0 bottom-0 left-0 right-0 m-auto" />
          )}
        </label>
        {label && <label className="text-sm">{label}</label>}
      </div>
      {error && (
        <p
          className={mergeClasses(
            "text-red-600 text-[13px]",
            errorClassname as string
          )}
        >
          {error.message}
        </p>
      )}
    </div>
  );
};

export default Checkbox;
