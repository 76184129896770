import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Transition } from "@headlessui/react";
import { Button } from "components";
import {
  ArrowIcon,
  EmailIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TikTokIcon,
  YouTubeIcon,
} from "icons";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import CookieConsent from "react-cookie-consent";
import { IFooterFields, ILinkFields, INavigationFields } from "types";
import { mergeClasses } from "utils";

const NavigationItem: React.FC<ILinkFields> = ({ url, label, active }) => {
  const { pathname } = useRouter();
  const isActive = !!active?.length
    ? active.includes(pathname)
    : pathname === url;

  return (
    <li className="relative">
      <Link href={url} passHref>
        <a className="font-medium text-[13px] hover:opacity-80 hover:transition-opacity-200 hover:duration-200">
          {label}
        </a>
      </Link>
      <div
        className={mergeClasses(
          "h-0.5 w-full bg-accent absolute left-0 -bottom-3.5 transition-all origin-center",
          isActive ? "scale-100" : "scale-0"
        )}
      />
    </li>
  );
};

const Navigation: React.FC<{ navigation: INavigationFields[] }> = ({
  navigation,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNavShrinked, setNavShrinked] = useState(false);
  const genericHamburgerLine = `h-[1px] w-7 my-[5.5px] rounded-full bg-black transition ease transform duration-300`;

  const { pathname } = useRouter();

  const greyBackgroundPages = [
    "/",
    "/team",
    "/jetson-one",
    "/press",
    "/careers/[careerSlug]",
  ].includes(pathname);

  const changeNavSize = () => {
    if (window.scrollY >= 10) {
      setNavShrinked(true);
    } else {
      setNavShrinked(false);
    }
  };
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", changeNavSize);
  }

  return (
    <div className="lg:mb-[128px] mb-20">
      {navigation.map((nav, index) => (
        <nav
          className={mergeClasses(
            "fixed z-50 w-full transition-all duration-200 top-0 left-0",
            isNavShrinked ? "py-4" : "py-4 lg:py-9",
            greyBackgroundPages
              ? "bg-[#F4F4F2]"
              : isOpen
              ? "bg-[#F4F4F2] md:bg-white"
              : "bg-white"
          )}
          key={index}
        >
          <div className="mx-auto flex justify-center flex-col container w-full">
            <div className="grid justify-between grid-cols-3 md:flex w-full">
              <div className="flex items-center justify-between flex-auto col-start-2 relative">
                <div className="flex-shrink-0 lg:w-60 w-40">
                  <Link href={"/"} passHref>
                    <div className="relative h-8 lg:h-12 w-32 lg:w-48 cursor-pointer">
                      <Image
                        alt="jetson logo"
                        layout="fill"
                        priority
                        src="/assets/images/jetson_logo.png"
                      />
                    </div>
                  </Link>
                </div>
                <div className="hidden md:block">
                  <ul className="flex flex-row items-center justify-end md:space-x-5 lg:space-x-10 xl:space-x-8 2xl:space-x-16 lg:w-full">
                    {nav.navLinks?.map((navigationLink, i) => (
                      <NavigationItem
                        active={navigationLink.fields.active}
                        key={i}
                        label={navigationLink.fields.label}
                        url={navigationLink.fields.url}
                      />
                    ))}
                  </ul>
                </div>
                <div className="lg:w-60 w-56">
                  <Link href={nav.buttonUrl} passHref>
                    <a className="md:block hidden">
                      <Button
                        className="!justify-between mx-auto button-primary md:w-auto !px-4 !py-4 lg:text-[13px] md:text-[9px] mr-2"
                        label={nav.buttonText}
                        onClick={() => setIsOpen(!isOpen)}
                        rightIcon={ArrowIcon}
                        rightIconClassName="text-accent lg:ml-5 md:ml-1 lg:!w-6 !h-6 md:!w-4"
                      />
                    </a>
                  </Link>
                </div>
              </div>
              <div className="flex items-center justify-end flex-auto col-start-3 -mr-2 md:hidden">
                <button
                  className="flex flex-col items-center justify-center w-12 h-12 mr-6 group "
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <div
                    className={mergeClasses(
                      genericHamburgerLine,
                      isOpen
                        ? "rotate-45 translate-y-3 group-hover:opacity-100"
                        : " group-hover:opacity-100"
                    )}
                  />
                  <div
                    className={mergeClasses(
                      genericHamburgerLine,
                      isOpen ? "opacity-0" : " group-hover:opacity-100"
                    )}
                  />
                  <div
                    className={mergeClasses(
                      genericHamburgerLine,
                      isOpen
                        ? "-rotate-45 -translate-y-3  group-hover:opacity-100"
                        : "group-hover:opacity-100"
                    )}
                  />
                </button>
              </div>
            </div>
          </div>

          <Transition
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            show={isOpen}
          >
            <div className="z-10 w-full h-full bg-[#F4F4F2]">
              <div className="md:hidden bg-star bg-[center_top_-2rem] bg-no-repeat -mt-4">
                <div className="h-screen px-2 text-center sm:px-3 ">
                  <ul className="flex flex-col items-center justify-center pt-10 sm:pt-10">
                    {nav.navLinks?.map(({ fields }, i) => {
                      const isActive = !!fields.active?.length
                        ? fields.active.includes(pathname)
                        : pathname === fields.url;

                      return (
                        <li className="flex justify-center w-full" key={i}>
                          <Link href={fields.url} passHref>
                            <a
                              className={mergeClasses(
                                "py-8 sm:py-3 px-32 font-medium text-[14px] hover:opacity-80 hover:transition-opacity-200 hover:duration-200",
                                isActive ? "text-accent" : ""
                              )}
                              onClick={() => setIsOpen(!isOpen)}
                            >
                              {fields.label}
                            </a>
                          </Link>
                        </li>
                      );
                    })}
                    <Link href={nav.buttonUrl} passHref>
                      <a className="lg:hidden block">
                        <Button
                          className="!justify-between mx-auto button-primary md:w-auto !px-4 !py-4 ml-auto"
                          label={nav.buttonText}
                          onClick={() => setIsOpen(!isOpen)}
                          rightIcon={ArrowIcon}
                          rightIconClassName="text-accent ml-5 !w-6 !h-6"
                        />
                      </a>
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
          </Transition>
        </nav>
      ))}
    </div>
  );
};

const SocialLink: React.FC<{ href: string; icon: any }> = ({
  href,
  icon: Icon,
}) => {
  return (
    <div className="flex flex-row self-start">
      <a href={href} rel="noreferrer" target="_blank">
        <Icon className="border-[1px] rounded-[80px] transition ease-in-out hover:scale-110 duration-200 w-[36px!important] h-[36px!important]" />
      </a>
    </div>
  );
};

const Footer: React.FC<{ footerSection?: IFooterFields[] }> = ({
  footerSection,
}) => {
  return (
    <div className="flex flex-col bg-[#F4F4F1] pb-12">
      <div className="container">
        {footerSection?.map((footer, index) => (
          <div className="mt-12" key={index}>
            <h3 className="text-gray text-[15px] mt-8 text-center">
              Featured in
            </h3>
            <div className="mt-14 lg:mt-8 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[72px]">
              {footer.feturedIn.map(({ fields }, i) => (
                <div
                  className="border-l-4 border-accent flex flex-col justify-between"
                  key={i}
                >
                  <div className="text-[18px] lg:text-2xl  font-serif text-left flex flex-col justify-start font-semibold">
                    <p className=" pl-[24px] leading-[32px] lg:leading-[43px]">
                      {fields.description}
                    </p>
                  </div>
                  <div className="pt-[21px] lg:pt-[27px] pl-[24px] flex flex-row justify-between items-center">
                    <span className="relative h-4 lg:h-6 w-24 lg:w-32">
                      <Image
                        alt="brand logo"
                        layout="fill"
                        objectFit="contain"
                        src={`https:${fields.brandLogo.fields.file.url}`}
                      />
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div className="relative flex pt-[93px] pb-[38px] items-center">
              <div className="flex-grow border-t border-gray opacity-20"></div>
            </div>
            <div className="flex flex-col items-center justify-centers lg:justify-between lg:flex-row lg:h-[84px] gap-3">
              <div>
                <Image
                  alt="jetson footer logo"
                  height={32}
                  src="/assets/images/jetson_logo.png"
                  width={128}
                />
              </div>
              <div className="border-[1px] h-full lg:block hidden"></div>
              <div className="flex flex-col text-[15px] lg:self-start text-gray-500 text-center lg:text-left">
                {documentToReactComponents(footer.adress)}
              </div>
              <div className="border-[1px] h-full lg:block hidden"></div>
              <p className="text-[15px] text-gray-500 text-center lg:text-left max-w-[502px]">
                {footer.copyright}
              </p>
              <div className="border-[1px] h-full lg:block hidden"></div>
              <div className="flex flex-row">
                <div className="flex flex-row self-start border-[1px] rounded-[80px]">
                  <EmailIcon className="p-2 transition ease-in-out hover:scale-110 duration-200 w-[36px!important] h-[36px!important]" />
                </div>
                <a
                  className="self-center pl-[16px]"
                  href={`mailto:${footer.email}`}
                >
                  {footer.email}
                </a>
              </div>
              <div className="border-[1px] h-full lg:block hidden"></div>
              <div className="flex flex-row !self-center pb-10 lg:self-start lg:pb-0">
                <SocialLink href={footer.facebookUrl} icon={FacebookIcon} />
                <div className=" relative flex flex-col self-center w-[10px] border-t border-gray mx-[10px] opacity-20" />
                <SocialLink href={footer.instagramUrl} icon={InstagramIcon} />
                <div className="relative flex flex-col self-center w-[10px] border-t border-gray mx-[10px] opacity-20"></div>
                <SocialLink href={footer.youtubeUrl} icon={YouTubeIcon} />
                <div className="relative flex flex-col self-center w-[10px] border-t border-gray mx-[10px] opacity-20"></div>
                <SocialLink href={footer.tiktokUrl} icon={TikTokIcon} />
                <div className="relative flex flex-col self-center w-[10px] border-t border-gray mx-[10px] opacity-20"></div>
                <SocialLink href={footer.linkedinUrl} icon={LinkedinIcon} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center mt-4 flex flex-row flex-wrap justify-center gap-8 text-gray-400 mb-8">
        <p>
          <a href="/privacy-policy" target="_blank">
            Privacy Policy
          </a>
        </p>
        <span>•</span>
        <p>
          <a href="/cookies-policy" target="_blank">
            Cookies Policy
          </a>
        </p>
        <span className="hidden 1sm:block">•</span>
        <p>
          <a href="https://hoq.us/">Website developed by HOQUS</a>
        </p>
      </div>
    </div>
  );
};

const PageLayout: React.FC<{
  footer?: IFooterFields[];
  navigation: INavigationFields[];
}> = ({ children, footer, navigation }) => {
  return (
    <div>
      <Navigation navigation={navigation} />
      <main className="w-full h-auto min-h-screen">{children}</main>
      <Footer footerSection={footer} />
      <CookieConsent
        buttonStyle={{
          color: "#fff",
          fontSize: "16px",
          background: "black",
          fontWeight: "bold",
          padding: "6px 50px",
        }}
        buttonText="Agree"
        cookieName="jetsonCookie"
        expires={150}
        location="bottom"
        style={{ background: "white", opacity: "0.9", color: "black" }}
      >
        We use&nbsp;
        <a className="text-accent" href="/cookies-policy" target="_blank">
          cookies
        </a>
        &nbsp;to make sure you have the best experience on our website. If you
        continue to use this site we assume that you will be happy with them.
      </CookieConsent>
    </div>
  );
};

export default PageLayout;
