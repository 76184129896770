import Image from "next/image";
import { IJetsonOneHeaderSectionFields } from "types";

const JetsonOneHeaderSection: React.FC<{
  headerSection: IJetsonOneHeaderSectionFields[];
}> = ({ headerSection }) => {
  return (
    <section>
      <div className="container pb-section pt-5">
        {headerSection.map((header, index) => (
          <div
            className="relative w-full h-96 md:h-[460px] lg:h-[660px] bg-black overflow-hidden rounded-[48px] flex justify-center lg:justify-start items-center"
            key={index}
          >
            <Image
              alt="team"
              className="object-cover opacity-60 w-full"
              layout="fill"
              objectFit="cover"
              priority
              src="/assets/images/jetsonone-cover.jpeg"
            />
            <div className="lg:text-left text-center absolute text-white lg:pl-[140px]">
              <h1 className="heading-1 mb-2 lg:mb-4">{header.title}</h1>
              <ul className="space-y-2">
                {header.jetsonOneHeaderList.map((listItem, i) => (
                  <li key={i}>
                    <h2 className="subheading-1 max-w-[721px] mx-4">
                      <span className="font-bold">• </span>
                      {listItem.fields.headerListItem}
                    </h2>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default JetsonOneHeaderSection;
