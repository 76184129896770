import * as yup from "yup";
import { validationPatterns } from "../../../../utils/validationPatterns";
import { PaymentMethodFormEnum } from "./WireTransferMethod";

export const wireTransferFormSchema = () =>
  yup.object().shape(
    {
      [PaymentMethodFormEnum.State]: yup
        .string()
        .when(PaymentMethodFormEnum.Country, {
          is: (value: string) => value === "United States",
          then: yup.string().required("Please provide state"),
          otherwise: yup.string().notRequired(),
        }),
      [PaymentMethodFormEnum.Street]: yup
        .string()
        .required("Please provide address")
        .matches(/^[a-z0-9-.]/gi, "Address is incorrect"),
      [PaymentMethodFormEnum.FullName]: yup
        .string()
        .required("Please provide your Full name")
        .max(100, "Full name is too long")
        .matches(
          validationPatterns.onlyLettersPattern,
          "Full name can only contain letters."
        ),
      [PaymentMethodFormEnum.ZipCode]: yup
        .string()
        .required("Please provide postal code")
        .max(12, "Postal code can be up to 12 characters long")
        .matches(/^[0-9-]+$/g, "Postal code is incorrect"),

      [PaymentMethodFormEnum.VatId]: yup
        .string()
        .when(PaymentMethodFormEnum.VatId, {
          is: "",
          then: yup.string().notRequired(),
          otherwise: yup
            .string()
            .matches(validationPatterns.vatIdPattern, "VAT ID incorrect"),
        }),
      [PaymentMethodFormEnum.Email]: yup
        .string()
        .required("Please provide email")
        .matches(validationPatterns.emailPattern, "Email address is incorrect"),
      [PaymentMethodFormEnum.TermsAccepted]: yup
        .boolean()
        .isTrue("T&C is required field")
        .required("T&C is required field"),
      [PaymentMethodFormEnum.City]: yup
        .string()
        .required("Please provide city")
        .max(100, "City is too long")
        .matches(
          validationPatterns.onlyLettersPattern,
          "City can only contain letters."
        ),
    },
    [["vatId", "vatId"]]
  );
