import { MouseIcon } from "icons";
import { useEffect, useState } from "react";
import { IHeroSectionFields } from "types";

const HeroSection: React.FC<{ heroSection: IHeroSectionFields[] }> = ({
  heroSection,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const MOBILE_BREAKPOINT = 1023;

  const handleResize = () => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="bg-[#F4F4F2]">
      <div className="container">
        <div className="absolute flex justify-center bg-black lg:rounded-[80px] rounded-[48px] w-[calc(100%-40px)] mx-auto max-w-[1240px] 2xl:max-w-[1610px] h-[688px] lg:h-[856px] overflow-hidden">
          <video
            autoPlay
            className="object-cover opacity-60 w-full lg:rounded-[80px] rounded-[48px]"
            loop
            muted
            playsInline
          >
            <source
              src={
                isMobile
                  ? "assets/videos/jetson_one_background_mobile.mp4"
                  : "assets/videos/jetson_one_background_desktop.mp4"
              }
              type="video/mp4"
            />
          </video>
        </div>
        <div className="flex justify-center flex-col text-center h-[688px] lg:h-[952px] relative lg:pb-[88px]">
          {heroSection.map((hero, index) => (
            <div className="text-white" key={index}>
              <div>
                <h1 className="heading-1 lg:mb-4 mb-2">{hero.title}</h1>
                <p className="subheading-1 sm:px-4 mx-auto max-w-[295px] sm:max-w-md lg:max-w-[711px]">
                  {hero.description}
                </p>
              </div>
              <div className="absolute mt-40 lg:mt-56  left-0 right-0 mx-auto">
                <p className="text-[11px] lg:text-[13px] uppercase">
                  Discover jetson’s features
                </p>
                <MouseIcon className="flex m-auto my-3 text-accent" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
