import { Button } from "components";
import { ArrowIcon } from "icons";
import Link from "next/link";
import { IDeliverySectionFields } from "types";

const DeliverySection: React.FC<{
  deliverySection: IDeliverySectionFields[];
}> = ({ deliverySection }) => {
  return (
    <section className="bg-black">
      {deliverySection.map((delivery, index) => (
        <div className="container text-center py-section" key={index}>
          <h2 className="heading-1 text-white">{delivery.title}</h2>
          <p className="subheading-2 text-white mt-4">{delivery.subtitle}</p>
          <Link href={delivery.buttonUrl} passHref>
            <a>
              <Button
                className="!justify-between mx-auto button-secondary md:w-auto !px-8 !py-4 mt-16"
                label={delivery.buttonText}
                rightIcon={ArrowIcon}
                rightIconClassName="text-accent ml-5 !w-6 !h-6"
              />
            </a>
          </Link>
        </div>
      ))}
    </section>
  );
};
export default DeliverySection;
