import * as yup from "yup";
import { NewsletterSectionFormEnum } from "./NewsletterSection";

export const newsletterSectionFormSchema = () =>
  yup.object().shape({
    [NewsletterSectionFormEnum.TermsAgreement]: yup
      .string()
      .required("You have to accept newsletter agreement"),
    [NewsletterSectionFormEnum.Email]: yup
      .string()
      .required("E-mail address is required")
      .email("Please use valid e-mail address"),
  });
