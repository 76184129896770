import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Button } from "components";
import { ArrowIcon } from "icons";
import { IOrderBannerSectionFields } from "types";

const OrderBannerSection: React.FC<{
  bannerSection: IOrderBannerSectionFields[];
}> = ({ bannerSection }) => {
  return (
    <section className="bg-white text-center items-center">
      {bannerSection.map((banner, index) => (
        <div key={index}>
          <h1 className="heading-1 mb-2 lg:mb-4">{banner.title}</h1>
          <div className="subheading-1 max-w-sm lg:max-w-3xl mx-auto space-y-6">
            {documentToReactComponents(banner.description)}
          </div>
          <div>
            <Button
              className="!justify-between mx-auto mt-8 lg:mt-16 button-primary !px-8 !py-4 z-10 relative"
              label={banner.buttonText}
              onClick={() => window.location.replace(banner.buttonUrl)}
              rightIcon={ArrowIcon}
              rightIconClassName="text-accent ml-5 !w-6 !h-6 rotate-90"
            />
          </div>
          <div
            className=" bg-bottom w-full bg-no-repeat h-[523px] lg:h-[686px] 4xl:h-[996px] bg-cover lg:bg-[length:1920px] 4xl:bg-[length:2820px] lg:mt-10 mt-6"
            style={{
              backgroundImage: "url(/assets/images/background-banner.jpg)",
            }}
          ></div>
        </div>
      ))}
    </section>
  );
};

export default OrderBannerSection;
