import { ISafetySectionFields } from "types";
import { useCheckMobileScreen } from "utils";

const SafetyItem: React.FC<{
  info: string;
  number: string;
}> = ({ info, number = "" }) => {
  return (
    <div className="lg:py-0 py-5">
      <p className="text-3xl lg:text-[64px]">{number}</p>
      <p className="text-xl lg:text-2xl lg:mt-6">{info}</p>
    </div>
  );
};

const JetsonOneSafetySection: React.FC<{
  safetySection: ISafetySectionFields[];
}> = ({ safetySection }) => {
  const isMobile = useCheckMobileScreen();
  return (
    <section className="bg-white py-section">
      {safetySection.map((safety, index) => (
        <div
          className="container flex flex-col justify-center items-center"
          key={index}
        >
          <h2 className="heading-2 text-center lg:max-w-[762px]">
            Safety is our priority
          </h2>
          <div className=" lg:mt-[110px] mt-10 lg:bg-[url('/assets/images/safety-bg.png')] bg-no-repeat bg-none lg:bg-[length:122.5%] bg-center grid-cols-1 lg:grid-cols-9 grid lg:h-[800px]">
            <div className="flex flex-col justify-between lg:col-span-2 lg:text-left text-center lg:max-w-[364px]">
              {!isMobile ? (
                <>
                  <SafetyItem info={safety.safety1} number="01" />
                  <SafetyItem info={safety.safety3} number="03" />
                  <SafetyItem info={safety.safety5} number="05" />
                </>
              ) : (
                <>
                  <SafetyItem info={safety.safety1} number="01" />
                  <SafetyItem info={safety.safety2} number="02" />
                  <SafetyItem info={safety.safety3} number="03" />
                </>
              )}
            </div>
            <div className="lg:col-span-5 lg:block hidden"></div>
            <div className="flex flex-col justify-between lg:text-right text-center lg:max-w-[364px] lg:col-span-2">
              {!isMobile ? (
                <>
                  <SafetyItem info={safety.safety2} number="02" />
                  <SafetyItem info={safety.safety4} number="04" />
                  <SafetyItem info={safety.safety6} number="06" />
                </>
              ) : (
                <>
                  <SafetyItem info={safety.safety4} number="04" />
                  <SafetyItem info={safety.safety5} number="05" />
                  <SafetyItem info={safety.safety6} number="06" />
                </>
              )}
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};
export default JetsonOneSafetySection;
