import Image from "next/image";
import { IGallerySectionFields } from "types";

const JetsonOneGallerySection: React.FC<{
  gallerySection: IGallerySectionFields[];
}> = ({ gallerySection }) => {
  return (
    <section className="bg-white py-section">
      {gallerySection.map((gallery, index) => (
        <div
          className="container flex flex-col lg:flex-row gap-4 lg:gap-8"
          key={index}
        >
          <Image
            alt="jetson one"
            className=" rounded-[24px] lg:rounded-[48px]"
            height={880}
            priority
            src={`https:${gallery.image1.fields.file.url}`}
            width={670}
          />
          <div className="flex flex-col gap-4 lg:gap-8">
            <Image
              alt="jetson one"
              className=" rounded-[24px] lg:rounded-[48px]"
              height={425}
              priority
              src={`https:${gallery.image2.fields.file.url}`}
              width={950}
            />
            <div className="flex flex-row gap-4 lg:gap-8">
              <Image
                alt="jetson one"
                className=" rounded-[24px] lg:rounded-[48px]"
                height={425}
                priority
                src={`https:${gallery.image3.fields.file.url}`}
                width={390}
              />
              <Image
                alt="jetson one"
                className=" rounded-[24px] lg:rounded-[48px]"
                height={425}
                priority
                src={`https:${gallery.image4.fields.file.url}`}
                width={530}
              />
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};
export default JetsonOneGallerySection;
