import { CrossIcon } from "icons";
import { ReactNode, useEffect } from "react";
import { mergeClasses } from "utils";

export interface ModalProps {
  children: ReactNode;
  isModalOpen: boolean;
  title?: string | ReactNode;
  closeActions?: () => void;
  overflowY?: string;
  className?: string;
}

const Modal = ({
  children,
  isModalOpen,
  title,
  closeActions,
  className = "w-[clamp(38%,612px,90%)]",
}: ModalProps) => {
  useEffect(() => {
    const openedModals =
      document.querySelector("#modal-root")?.childNodes.length ?? 0;
    if (isModalOpen && document.body.style.overflow !== "hidden") {
      document.body.style.overflow = "hidden";
    } else if (openedModals === 0) {
      document.body.style.overflow = "visible";
    }
  }, [isModalOpen]);

  return isModalOpen ? (
    <div className="fixed top-0 left-0 bottom-0 right-0 flex items-center justify-center z-50 bg-[#D9D9D9] bg-opacity-75">
      <div
        className={mergeClasses(
          "h-auto rounded border bg-white relative opacity-100",
          className
        )}
      >
        <div
          className="absolute top-5 right-5 lg:top-10 lg:right-10 z-10 cursor-pointer flex w-6 h-6 justify-center items-center opacity-100"
          onClick={closeActions}
        >
          <CrossIcon className="w-2 h-2" />
        </div>
        <div className="pt-[75px] px-[16.5px] lg:px-[96px]">
          {title && (
            <div>
              <h1>{title}</h1>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
