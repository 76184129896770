import { Button } from "components";
import { ArrowIcon } from "icons";
import Image from "next/image";
import { IPressItemFields } from "types";

const PressList: React.FC<{ press: IPressItemFields[] }> = ({ press }) => {
  return (
    <section className="bg-white">
      <div className="container py-section">
        <div className="mt-14 lg:mt-8 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[72px]">
          {press?.map((pressItem, index) => (
            <div
              className="border-l-4 border-accent flex flex-col justify-between"
              key={index}
            >
              <div className="text-[18px] lg:text-2xl  font-serif text-left flex flex-col justify-start font-semibold">
                <p className=" pl-[24px] leading-[32px] lg:leading-[43px]">
                  {pressItem.title}
                </p>
              </div>
              <div className="pt-[21px] lg:pt-[27px] flex flex-row justify-between items-center">
                <span className="relative h-4 lg:h-6 w-24 lg:w-24 ml-[24px]">
                  <Image
                    alt="brand logo"
                    layout="fill"
                    objectFit="contain"
                    src={`https:${pressItem.brandLogo.fields.file.url}`}
                  />
                </span>
                <div>
                  <a
                    href={pressItem.articleUrl}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Button
                      label="READ MORE"
                      rightIcon={ArrowIcon}
                      rightIconClassName="text-accent ml-3"
                    />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PressList;
