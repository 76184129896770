import { Button, NewsItem } from "components";
import { ArrowIcon } from "icons";
import Link from "next/link";
import { INews } from "types";

const LatestNewsSection: React.FC<{
  news: INews[];
}> = ({ news }) => {
  return (
    <section className="bg-white py-section">
      <div className="container">
        <h2 className="heading-2 text-center mb-[48px] lg:mb-[80px]">
          Latest News
        </h2>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 flex-col items-start gap-8 pb-[48px] lg:pb-[64px]">
          {news.map((newsItem, index) => (
            <NewsItem key={`news_${index}`} {...newsItem} />
          ))}
        </div>
        <div>
          <Link href="news" passHref>
            <a>
              <Button
                className="!justify-between w-[176px] mx-auto button-primary md:w-auto !px-8 !py-4"
                label="more news"
                rightIcon={ArrowIcon}
                rightIconClassName="text-accent ml-5 !w-6 !h-6"
              />
            </a>
          </Link>
        </div>
      </div>
    </section>
  );
};
export default LatestNewsSection;
