import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Button, FeesModal } from "components";
import { ArrowIcon, InfoIcon } from "icons";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import { IPreorderSectionFields } from "types";

const PreorderSection: React.FC<{
  preorderSection: IPreorderSectionFields;
}> = ({ preorderSection }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <section className="bg-white" id="orderForm">
      <div className="container py-section">
        <FeesModal
          description={documentToReactComponents(
            preorderSection.feeInformation
          )}
          isModalOpen={isModalOpen}
          setModalClose={() => setModalOpen(false)}
        />
        <div className="text-center heading-2 lg:mb-[105px] mb-10 lg:block hidden">
          <h1>{preorderSection.title}</h1>
        </div>
        <div className="flex lg:flex-row flex-col lg:gap-[161px] gap-10 items-center">
          <div className="rounded-[32px] w-full max-w-[831px] h-[498px] lg:h-[463px] relative">
            <Image
              alt="preorder image"
              className="w-full h-full !relative object-cover rounded-[16px] lg:rounded-[32px]"
              layout="fill"
              src={`https:${preorderSection.preorderImage.fields.file.url}`}
            />
          </div>
          <div className="text-center text-[40px] lg:mb-[105px] mb-10 lg:hidden block">
            <h1>{preorderSection.title}</h1>
          </div>
          <div className="lg:min-w-[463px] lg:max-w-[530px]">
            <div className="mb-10">
              <h3 className="font-semibold">
                {preorderSection.currentDeliveryTitle}
              </h3>
              <p className="text-gray-500">
                {preorderSection.currentDeliveryDescription}
              </p>
            </div>
            <div className="flex flex-row items-start justify-between mb-6">
              <div>
                <h3 className="font-semibold">
                  {preorderSection.fullPriceTitle}
                </h3>
                <div className="flex flex-row items-center">
                  <p className="text-gray-500 mr-2">
                    {preorderSection.fullPriceDescription}
                  </p>
                  <div
                    className="cursor-pointer"
                    onClick={() => setModalOpen(true)}
                    role="button"
                  >
                    <InfoIcon className="!w-[14px] !h-[14px]" />
                  </div>
                </div>
              </div>
              <div>
                <p className="font-semibold">
                  ${preorderSection.fullPrice?.toLocaleString()}
                </p>
              </div>
            </div>
            <hr />
            <div className="mt-6 flex flex-row items-center justify-between">
              <div>
                <h3 className="text-2xl">{preorderSection.dueTodayTitle}</h3>
                <p className="text-gray-500">
                  {preorderSection.dueTodayDescription}
                </p>
              </div>
              <div>
                <p className="text-2xl md:text-[32px] md:leading-[1.1]">{`$${preorderSection.dueTodayPrice?.toLocaleString()}`}</p>
              </div>
            </div>
            <p className="text-sm leading-[14px] mt-4 bg-light-gray p-4 rounded-xl pr-16">
              {preorderSection.preorderDescription}
            </p>
            <div className="my-6">
              <Link href="/preorder/payment?method=card" passHref>
                <a>
                  <Button
                    className="button-primary"
                    label="order with credit card"
                    rightIcon={ArrowIcon}
                    rightIconClassName="text-accent ml-7"
                  />
                </a>
              </Link>
            </div>
            <div>
              <p className="text-sm leading-4 mb-6">
                Our Sales Representative will contact you as soon as possible to
                discuss all payment and delivery details.
              </p>
              <p className="text-sm leading-4">
                Prefer to pay with wire transfer?
                <Link href="/preorder/payment?method=transfer" passHref>
                  <a className="ml-1 link">Order now with wire transfer</a>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PreorderSection;
