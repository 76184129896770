import { IconType } from "icons";
import { mergeClasses } from "utils";

export interface ButtonProps {
  label?: string | JSX.Element;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  leftIcon?: IconType;
  rightIcon?: IconType;
  leftIconClassName?: string;
  rightIconClassName?: string;
  type?: "button" | "submit" | "reset" | undefined;
  id?: string;
}

const Button: React.FC<ButtonProps> = ({
  className = "",
  label = "",
  type = "button",
  disabled,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  leftIconClassName = "w-4 h-4",
  rightIconClassName = "w-4 h-4",
  children,
  id,
  ...props
}) => {
  return (
    <button
      className={mergeClasses(
        "button",
        className,
        disabled ? "!bg-[#BDBDBD]" : ""
      )}
      disabled={disabled}
      id={id}
      type={type}
      {...props}
    >
      {LeftIcon ? <LeftIcon className={leftIconClassName} /> : null}
      {children ?? <>{label ? <span>{label}</span> : null}</>}
      {RightIcon ? (
        <RightIcon
          className={mergeClasses(
            rightIconClassName,
            disabled ? "!hidden" : ""
          )}
        />
      ) : null}
    </button>
  );
};

export default Button;
