import { NewsItem } from "components";
import { INews } from "types";

const NewsSection: React.FC<{
  news: INews[];
}> = ({ news }) => {
  return (
    <section className="bg-white">
      <div className="container py-section">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 items-start flex-col gap-[30px] space-y-[32px] md:space-y-0 pb-[48px] lg:pb-[64px]">
          {news.map((newsItem, index) => (
            <NewsItem key={`news_${index}`} {...newsItem} />
          ))}
        </div>
      </div>
    </section>
  );
};
export default NewsSection;
