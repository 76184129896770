import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Image from "next/image";
import { ITeamFoundersSectionFields } from "types";

const HowItBeganSection: React.FC<{
  foundersSection: ITeamFoundersSectionFields[];
}> = ({ foundersSection }) => {
  return (
    <section className="bg-white py-section">
      {foundersSection.map((founders, index) => (
        <div
          className="container flex flex-col lg:flex-row justify-between items-center"
          key={index}
        >
          <div className="mb-10 lg:mb-0 mr-10">
            <h2 className="heading-2 mb-8">{founders.title}</h2>
            <div className="space-y-6 subheading-1 max-w-[730px]">
              {documentToReactComponents(founders.description)}
            </div>
          </div>
          <Image
            alt=""
            height={670}
            src={`https:${founders.foundersImage.fields.file.url}`}
            width={670}
          />
        </div>
      ))}
    </section>
  );
};

export default HowItBeganSection;
