import { forwardRef } from "react";
import { ChangeHandler, FieldError, FieldErrors } from "react-hook-form";
import { mergeClasses } from "utils";

interface TextFieldProps {
  type?: "text" | "number" | "password";
  value?: string | number;
  label?: string;
  name: string;
  errors?: FieldErrors;
  onChange?: ChangeHandler;
  onBlur?: ChangeHandler;
  isRequired?: boolean;
  autoComplete?: string;
  maxLength?: number;
  placeholder?: string;
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      type = "text",
      label,
      value,
      errors,
      name,
      onBlur,
      onChange,
      isRequired = false,
      autoComplete,
      maxLength,
      placeholder = "",
    },
    ref
  ) => {
    const error: FieldError = errors && errors[name];

    return (
      <label className="block" htmlFor={name}>
        <div className="relative">
          {label && (
            <p className="text-base">
              {label}
              {isRequired && <span className="text-red-600">*</span>}
            </p>
          )}
          <input
            autoComplete={autoComplete}
            className={mergeClasses(
              "p-4 border text-base w-full peer mt-2",
              error ? "border-red-600" : "border-[#DDDDDC]"
            )}
            id={name}
            maxLength={maxLength}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            ref={ref}
            type={type}
            value={value}
          />
        </div>
        {error ? (
          <p className="text-red-600 text-[13px]">{error.message}</p>
        ) : null}
      </label>
    );
  }
);

export default TextField;
