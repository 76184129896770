import { Loader } from "components";

const PaymentSuccessSection = () => {
  return (
    <section className="bg-white min-h-[calc(100vh-128px)] flex flex-col items-center justify-center text-center">
      <div className="-mt-32">
        <div className="h-10">
          <Loader className="!relative" />
        </div>
        <p className="text-lg mt-6 font-medium">
          Your payment is being processed, please wait...
        </p>
      </div>
    </section>
  );
};

export default PaymentSuccessSection;
