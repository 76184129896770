import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import Image from "next/image";
import Link from "next/link";
import { INews } from "types";
import { truncateText } from "utils";

const NewsItem: React.FC<INews> = ({
  mainImage,
  slug,
  title,
  shortContent,
  content,
}) => {
  return (
    <div className="lg:max-w-[530px]">
      <div className="w-full h-[320px] relative">
        <Link href={`/news/${slug}`} passHref>
          <a>
            <Image
              alt={mainImage?.fields.file.fileName}
              className="w-full h-full !relative object-cover rounded-[16px] lg:rounded-[32px]"
              layout="fill"
              priority
              src={`https:${mainImage?.fields.file.url}`}
            />
          </a>
        </Link>
      </div>

      <div className="lg:min-h-[102px]">
        <Link href={`/news/${slug}`} passHref>
          <a className="hover:underline">
            <h4 className="text-[24px] lg:text-[32px] pt-[16px] lg:pt-[32px] leading-[26px] lg:leading-[35px]">
              {title}
            </h4>
          </a>
        </Link>
      </div>
      <div className="pt-[16px] lg:pt-[24px] leading-[19px] lg:leading-[25px] text-14px lg:text-[18px]">
        {shortContent
          ? shortContent
          : truncateText(documentToPlainTextString(content), 250)}
      </div>
    </div>
  );
};

export default NewsItem;
