import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Checkbox, LoadingCircle } from "components";
import { ArrowIcon } from "icons";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { mergeClasses } from "utils";
import { PaymentFormFields } from "./PaymentForm";

export enum CheckoutFormFields {
  Terms = "termsAccepted",
}

const CheckoutForm: React.FC<{
  setPaymentCompleted: () => void;
  onModalOpen: () => void;
}> = ({ setPaymentCompleted, onModalOpen }) => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();

  const termsAgreementValue = watch(PaymentFormFields.TermsAccepted);

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isStripeLoading, setIsStripLoading] = useState(true);

  const isFormDisabled =
    isLoading || !stripe || !elements || !termsAgreementValue;

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
      }
    });
  }, [stripe]);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    if (error?.type === "card_error") {
      setMessage(error?.message as string);
    } else if (error?.type === "validation_error") {
      setMessage("");
    } else {
      setPaymentCompleted();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (elements) {
      const element = elements.getElement("payment");
      element?.on("ready", () => {
        setIsStripLoading(false);
      });
    }
  }, [elements]);

  return (
    <form lang="en cc_checkout" onSubmit={onSubmit}>
      {isStripeLoading && (
        <div className="w-full h-[511px] flex justify-center items-center">
          <LoadingCircle className="w-[100px] h-[100px] text-accent" />
        </div>
      )}
      <div className="my-2">
        <PaymentElement />
      </div>
      <AddressElement
        options={{
          mode: "billing",
          fields: {
            phone: "always",
          },
          validation: {
            phone: {
              required: "never",
            },
          },
        }}
      />
      {!isStripeLoading && (
        <>
          <div className="mt-10">
            <Checkbox
              checked={termsAgreementValue}
              errors={errors}
              label={
                <>
                  I agree to the&nbsp;
                  <span
                    className="text-accent hover:underline cursor-pointer"
                    onClick={onModalOpen}
                  >
                    Jetson One Pre-Order Sale Agreement
                  </span>
                  &nbsp;and&nbsp;
                  <Link href="/privacy-policy" passHref>
                    <a
                      className="text-accent hover:underline"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </Link>
                  .&nbsp;
                </>
              }
              name={CheckoutFormFields.Terms}
              onChange={(checked) =>
                setValue(CheckoutFormFields.Terms, checked)
              }
            />
          </div>

          <button
            className={mergeClasses(
              "w-full flex justify-center py-4 mt-6 items-center text-white lg:max-w-[235px] button",
              isFormDisabled ? "bg-[#BDBDBD]" : "bg-black"
            )}
            disabled={isFormDisabled}
            id="submit"
          >
            <span>{isLoading ? "Processing payment..." : "PLACE ORDER"}</span>
            <ArrowIcon className="text-accent ml-6" />
          </button>
        </>
      )}
      {message && (
        <div className="w-full flex justify-center mt-4 bg-light-gray rounded-[12px] p-4 text-red-600">
          {message}
        </div>
      )}
    </form>
  );
};
export default CheckoutForm;
