/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  IArticleV3Fields,
  IJobsV3Fields,
  IPressItemFields,
} from "./generated/contentful";

export * from "./generated/contentful";

export interface INews extends IArticleV3Fields {
  createdAt: string;
}
export interface IPress extends IPressItemFields {
  createdAt: string;
}

export interface IJobs extends IJobsV3Fields {
  createdAt: string;
}

export interface TJob {
  id: number;
  application_form: string;
  advert: {
    recruitment: {
      id: number;
    };
    id: number;
    name: string;
  };
}

export interface AtsJob {
  id: number;
  name: string;
  status: string;
  _jobDescription?: string;
  _jobNeeds?: string;
}

export enum PaymentMethodsEnum {
  WireTransfer = "WireTransfer",
  Card = "Card",
}
