import { Button } from "components";
import { ArrowIcon } from "icons";
import { useRouter } from "next/router";

const PaymentCancelledSection = () => {
  const { push } = useRouter();

  return (
    <section className="bg-white min-h-[calc(100vh-128px)] flex flex-col items-center justify-center text-center">
      <div className="-mt-32">
        <p className="text-lg mt-6 font-medium">
          It looks like something went wrong with your payment, please try
          again.
        </p>
        <Button
          className="!justify-between w-[202px] mx-auto button-primary md:w-auto !px-8 !py-4 mt-6"
          label="Order again"
          onClick={() => push("/order#orderForm")}
          rightIcon={ArrowIcon}
          rightIconClassName="text-accent ml-5 !w-6 !h-6"
        />
      </div>
    </section>
  );
};

export default PaymentCancelledSection;
