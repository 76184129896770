import { Breadcrumbs } from "components";
import Image from "next/image";
import { IJobsV3Fields } from "types";
import { renderJobsContent } from "utils";

const SingleJob: React.FC<{ jobs: IJobsV3Fields }> = ({ jobs }) => {
  return (
    <section>
      <div className="container pb-section">
        <Breadcrumbs className="mb-8" slug={jobs.jobPosition} />
        <div className="relative w-full h-96 md:h-[460px] lg:h-[640px] bg-black overflow-hidden rounded-[48px] flex justify-center items-center">
          <Image
            alt="team"
            layout="fill"
            objectFit="cover"
            priority
            src="/assets/images/job-background.png"
          />
          <div className="text-center absolute text-white">
            <p className="text-accent text-2xl">Join us!</p>
            <h1 className="heading-1 mb-2 lg:mb-4">{jobs.jobPosition}</h1>
          </div>
        </div>
      </div>
      <div className="bg-white py-section">
        <div className="container grid lg:grid-cols-2 grid-cols-1 lg:text-left text-center gap-14">
          <div className="flex flex-col lg:max-w-[670px]">
            <h3 className="lg:text-[60px] text-5xl lg:max-w-lg pb-8">
              What are we looking for?
            </h3>
            <p className="text-[20px] text-justify">
              {renderJobsContent(jobs.jobDescription)}
            </p>
          </div>
          <div className="flex-col flex-col">
            <h3 className="lg:text-[60px] text-5xl pb-8 lg:text-left text-center">
              General needs of the company
            </h3>
            <p className="text-[20px] lg:max-w-[670px] text-justify">
              {renderJobsContent(jobs.jobRequirements)}
            </p>
          </div>
        </div>
        <div className="w-full flex flex-col gap-6 justify-center items-center container lg:pt-32 pt-12">
          <p className="md:text-xl text-lg max-w-lg md:text-center text-justify">
            Reach out to{" "}
            <span className="md:text-xl text-lg">
              <a className="text-accent" href="mailto:careers@jetson.com">
                careers@jetson.com
              </a>
            </span>{" "}
            with your name and the position you&quot;re applying for in the
            subject line
          </p>
          <p className="md:text-xl text-lg">
            Example: &quot;John Doe, Software Engineer&quot;
          </p>
        </div>
      </div>
    </section>
  );
};
export default SingleJob;
