import { Button } from "components";
import { ArrowIcon, LargeCheckIcon } from "icons";
import { useRouter } from "next/router";
import { IPreorderSectionFields } from "types";

const LongPaymentSuccessSection: React.FC<{
  preorder: IPreorderSectionFields;
}> = ({ preorder }) => {
  const { push, query } = useRouter();

  return (
    <section className="bg-white">
      <div className="flex flex-col items-center news-container py-4 md:py-10 text-center">
        <h2 className="text-[40px] md:text-[64px] leading-tight">Thank you</h2>
        <LargeCheckIcon className="text-accent !w-12 !h-12 mt-4 md:mt-[70px] mb-2 md:mb-8" />
        <p className="text-2xl mb-1">You’ve succesfully placed your order</p>
        <p className="mb-5 font-semibold">{preorder.currentDeliveryTitle}</p>
        {query.method === "card" ? (
          <p className="max-w-md mb-5 md:mb-8 leading-5">
            We will contact you regarding the final payment and delivery when
            your Jetson One is ready for shipping.
          </p>
        ) : (
          <>
            <p className="max-w-md mb-5 leading-5">
              Our Sales Representative will contact you as soon as possible to
              discuss all payment and delivery details.
            </p>
            <p className="max-w-md mb-5 md:mb-8 leading-5">
              Final payment will be required once your Jetson One is ready for
              shipping.
            </p>
          </>
        )}
        <p className="bg-light-gray max-w-lg w-full py-4 rounded-[12px]">
          Any further questions?&nbsp;
          <a className="underline text-accent" href="#">
            Contact us
          </a>
          .
        </p>
        <div className="w-full mt-8 md:mt-16 flex flex-col items-center pt-6 md:pt-14">
          <p>
            In the meantime, read about most recent Jetson One news and updates
          </p>
          <Button
            className="!justify-between button-primary md:w-auto !px-6 !py-4 lg:text-[13px] md:text-[9px] mt-6"
            label="See the latest news"
            onClick={() => push("/news")}
            rightIcon={ArrowIcon}
            rightIconClassName="text-accent lg:ml-5 md:ml-1 lg:!w-6 !h-6 md:!w-4"
          />
        </div>
      </div>
    </section>
  );
};

export default LongPaymentSuccessSection;
