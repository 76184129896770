import { ShortArrowIcon } from "icons";
import Link from "next/link";
import { useRouter } from "next/router";
import { mergeClasses } from "utils";

const BreadcrumbsItem: React.FC<{ href: string }> = ({
  children,
  href = "",
}) => (
  <Link href={href} passHref>
    <a className="font-medium text-gray last:text-accent last:pointer-events-none hover:opacity-80 uppercase transition-opacity duration-200">
      {children}
    </a>
  </Link>
);

type PathDisc = {
  [key: string]: {
    label: string;
    href: string;
  };
};

const Breadcrumbs: React.FC<{ className?: string; slug?: string }> = ({
  className = "",
  slug = "",
}) => {
  const { pathname } = useRouter();
  const pathDisc: PathDisc = {
    "": { label: "Home", href: "/" },
    news: { label: "News", href: "/news" },
    press: { label: "Press", href: "/press" },
    "[newsSlug]": { label: slug, href: "/news" },
    careers: { label: "Careers", href: "/careers" },
    "[careerSlug]": { label: slug, href: "/careers" },
  };
  const splittedPath = pathname.split("/");
  const breadcrumbsElements = splittedPath.map((path, index) => {
    const { label, href } = pathDisc[path] ?? {};
    return (
      <>
        <BreadcrumbsItem href={href}>{label}</BreadcrumbsItem>
        {index + 1 < splittedPath.length ? (
          <ShortArrowIcon className="!w-2 text-gray" />
        ) : null}
      </>
    );
  });
  return (
    <div className={mergeClasses("flex items-center space-x-4", className)}>
      {breadcrumbsElements}
    </div>
  );
};

export default Breadcrumbs;
