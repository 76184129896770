import React from "react";
import { mergeClasses } from "utils";

export interface SVGProps extends React.SVGProps<SVGSVGElement> {
  fill?: string;
  className?: string;
}

const SVGIcon = ({ className = "", ...props }) => {
  return <svg className={mergeClasses("icon", className)} {...props} />;
};

export type IconType = typeof SVGIcon;

export const ArrowIcon: IconType = (props) => (
  <SVGIcon fill="none" viewBox="0 0 24 24" {...props}>
    <path
      d="M20 12L15 9.11325V14.8868L20 12ZM4 12.5H15.5V11.5H4V12.5Z"
      fill="currentColor"
    />
  </SVGIcon>
);

export const CheckIcon: IconType = (props) => (
  <SVGIcon fill="none" viewBox="0 0 8 7" {...props}>
    <path d="M1 3.04545L2.96667 5L7 1" stroke="currentColor" strokeWidth="2" />
  </SVGIcon>
);

export const ShortArrowIcon: IconType = (props) => (
  <SVGIcon fill="none" viewBox="0 0 8 7" {...props}>
    <path
      d="M8 3.5L3 0.613249V6.38675L8 3.5ZM0 4H3.5V3H0V4Z"
      fill="currentColor"
    />
  </SVGIcon>
);

export const InstagramIcon: IconType = (props) => (
  <SVGIcon fill="none" viewBox="0 0 36 36" {...props}>
    <path
      d="M18 11.441c2.136 0 2.389.009 3.233.047.5072.0061 1.0095.0995 1.485.276.3478.1285.6622.3335.92.6.2665.2578.4715.5722.6.92.1765.4755.2699.9778.276 1.485.038.844.047 1.1.047 3.233s-.009 2.389-.047 3.233c-.0061.5072-.0995 1.0095-.276 1.485-.1328.3451-.3365.6585-.598.92-.2615.2615-.5749.4652-.92.598-.4755.1765-.9778.2699-1.485.276-.844.038-1.1.047-3.233.047s-2.389-.009-3.233-.047c-.5072-.0061-1.0095-.0995-1.485-.276-.3478-.1285-.6622-.3335-.92-.6-.2665-.2578-.4715-.5722-.6-.92-.1765-.4755-.2699-.9778-.276-1.485-.038-.844-.047-1.1-.047-3.233s.009-2.389.047-3.233c.0061-.5072.0995-1.0095.276-1.485.1285-.3478.3335-.6622.6-.92.2578-.2665.5722-.4715.92-.6.4755-.1765.9778-.2699 1.485-.276.844-.038 1.1-.047 3.233-.047l-.002.002ZM18 10c-2.173 0-2.445.009-3.3.048-.663.0135-1.319.1393-1.94.372-.534.2006-1.0177.5156-1.417.923-.4074.3993-.7224.883-.923 1.417-.2327.621-.3585 1.277-.372 1.94-.039.855-.048 1.127-.048 3.3s.009 2.445.048 3.3c.0135.663.1393 1.319.372 1.94.2006.534.5156 1.0177.923 1.417.3993.4074.883.7224 1.417.923.6216.233 1.2783.3587 1.942.372.853.039 1.125.048 3.298.048s2.445-.009 3.3-.048c.6637-.0133 1.3204-.139 1.942-.372.5314-.2057 1.014-.5201 1.417-.923.4029-.403.7173-.8856.923-1.417.233-.6216.3587-1.2783.372-1.942.039-.853.048-1.125.048-3.3s-.009-2.445-.048-3.3c-.0143-.6625-.1408-1.3178-.374-1.938-.2006-.534-.5156-1.0177-.923-1.417-.3993-.4074-.883-.7224-1.417-.923-.621-.2327-1.277-.3585-1.94-.372-.855-.039-1.127-.048-3.3-.048Z"
      fill="currentColor"
    />
    <path
      d="M18.0001 13.8916c-.8125 0-1.6067.2409-2.2823.6923-.6755.4514-1.2021 1.093-1.513 1.8436-.3109.7507-.3923 1.5767-.2338 2.3735.1585.7969.5498 1.5289 1.1243 2.1034.5745.5745 1.3065.9658 2.1034 1.1243.7968.1585 1.6228.0771 2.3735-.2338.7506-.3109 1.3922-.8375 1.8436-1.513.4514-.6756.6923-1.4698.6923-2.2823 0-.5395-.1063-1.0737-.3127-1.5721-.2065-.4984-.5091-.9512-.8905-1.3327-.3815-.3815-.8343-.684-1.3327-.8905-.4985-.2064-1.0326-.3127-1.5721-.3127Zm0 6.775c-.5275 0-1.0431-.1564-1.4817-.4495-.4386-.293-.7804-.7096-.9823-1.1969-.2019-.4873-.2547-1.0236-.1518-1.5409.1029-.5174.357-.9926.7299-1.3656.373-.3729.8482-.6269 1.3656-.7299.5173-.1029 1.0536-.05 1.5409.1518.4873.2019.9039.5437 1.1969.9823.2931.4386.4495.9542.4495 1.4817 0 .7073-.281 1.3857-.7812 1.8859-.5001.5001-1.1785.7811-1.8858.7811ZM22.2701 14.6895c.5302 0 .96-.4298.96-.96s-.4298-.96-.96-.96-.96.4298-.96.96.4298.96.96.96Z"
      fill="currentColor"
    />
  </SVGIcon>
);

export const TwitterIcon: IconType = (props) => (
  <SVGIcon fill="none" viewBox="0 0 36 36" {...props}>
    <path
      d="M26 13c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H10c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8Z"
      fill="currentColor"
    />
  </SVGIcon>
);

export const FacebookIcon: IconType = (props) => (
  <SVGIcon fill="none" viewBox="0 0 36 36" {...props}>
    <path
      d="M16.0229 26 16 19h-3v-3h3v-2c0-2.6992 1.6715-4 4.0794-4 1.1534 0 2.1447.0859 2.4335.1243v2.8208l-1.6699.0007c-1.3096 0-1.5631.6223-1.5631 1.5354V16H23l-1 3h-2.7201v7h-3.257Z"
      fill="currentColor"
    />
  </SVGIcon>
);

export const YouTubeIcon: IconType = (props) => (
  <SVGIcon fill="none" viewBox="0 0 36 36" {...props}>
    <path
      d="M25.8667 14.7999C25.8667 14.7999 25.7333 13.6666 25.2 13.1999C24.6 12.5332 23.9333 12.5332 23.6 12.5332C21.3333 12.3999 18 12.3999 18 12.3999C18 12.3999 14.6667 12.3999 12.4 12.5332C12.0667 12.5999 11.4 12.5999 10.8 13.1999C10.3333 13.6666 10.1333 14.7999 10.1333 14.7999C10.1333 14.7999 10 16.0666 10 17.3999V18.5999C10 19.8666 10.1333 21.1999 10.1333 21.1999C10.1333 21.1999 10.2667 22.3332 10.8 22.7999C11.4 23.4666 12.2 23.3999 12.5333 23.4666C13.8 23.5999 18 23.5999 18 23.5999C18 23.5999 21.3333 23.5999 23.6 23.3999C23.9333 23.3332 24.6 23.3332 25.2 22.7332C25.6667 22.2666 25.8667 21.1332 25.8667 21.1332C25.8667 21.1332 26 19.8666 26 18.5332V17.3332C26 16.0666 25.8667 14.7999 25.8667 14.7999ZM16.3333 20.0666V15.5999L20.6667 17.8666L16.3333 20.0666Z"
      fill="currentColor"
    />
  </SVGIcon>
);

export const TikTokIcon: IconType = (props) => (
  <SVGIcon fill="none" viewBox="0 0 36 36" {...props}>
    <path
      d="M16.7932 15.958V18.706C16.4471 18.6147 16.085 18.6023 15.7334 18.6696C15.3819 18.7369 15.0499 18.8822 14.762 19.0949C14.4741 19.3076 14.2376 19.5821 14.07 19.8983C13.9023 20.2146 13.8077 20.5644 13.7932 20.922C13.7729 21.2303 13.8188 21.5395 13.928 21.8285C14.0371 22.1176 14.2069 22.38 14.426 22.5979C14.6451 22.8158 14.9083 22.9843 15.198 23.092C15.4876 23.1996 15.797 23.244 16.1052 23.222C16.416 23.2456 16.7283 23.2015 17.0204 23.0928C17.3125 22.984 17.5775 22.8132 17.7972 22.5921C18.0169 22.371 18.186 22.1048 18.2929 21.812C18.3998 21.5192 18.4418 21.2067 18.4162 20.896V10H21.1352C21.5982 12.876 23.0352 13.544 24.9622 13.852V16.608C23.6275 16.4989 22.3399 16.0642 21.2122 15.342V20.787C21.2102 23.25 19.7502 26 16.1252 26C15.4453 25.9969 14.7728 25.8581 14.1472 25.5918C13.5215 25.3255 12.9553 24.937 12.4818 24.4491C12.0082 23.9612 11.6368 23.3836 11.3892 22.7503C11.1417 22.1171 11.023 21.4407 11.0402 20.761C11.0616 20.0531 11.2328 19.3579 11.5425 18.721C11.8523 18.0842 12.2935 17.5202 12.8371 17.0664C13.3807 16.6125 14.0144 16.2791 14.6963 16.088C15.3782 15.897 16.0929 15.8527 16.7932 15.958Z"
      fill="currentColor"
    />
  </SVGIcon>
);

export const LinkedinIcon: IconType = (props) => (
  <SVGIcon fill="none" viewBox="0 0 36 36" {...props}>
    <path
      d="M25.3 10H10.7C10.3 10 10 10.3 10 10.7V25.4C10 25.7 10.3 26 10.7 26H25.4C25.8 26 26.1 25.7 26.1 25.3V10.7C26 10.3 25.7 10 25.3 10ZM14.7 23.6H12.4V16H14.8V23.6H14.7ZM13.6 15C12.8 15 12.2 14.3 12.2 13.6C12.2 12.8 12.8 12.2 13.6 12.2C14.4 12.2 15 12.8 15 13.6C14.9 14.3 14.3 15 13.6 15ZM23.6 23.6H21.2V19.9C21.2 19 21.2 17.9 20 17.9C18.8 17.9 18.6 18.9 18.6 19.9V23.7H16.2V16H18.5V17C18.8 16.4 19.6 15.8 20.7 15.8C23.1 15.8 23.5 17.4 23.5 19.4V23.6H23.6Z"
      fill="currentColor"
    />
  </SVGIcon>
);

export const MouseIcon: IconType = (props) => (
  <SVGIcon fill="none" viewBox="0 0 16 16" {...props}>
    <path
      d="M8 4.5v4M13.5 6C13.5 2.96243 11.0376.5 8 .5 4.96243.5 2.5 2.96243 2.5 6v4c0 3.0376 2.46243 5.5 5.5 5.5 3.0376 0 5.5-2.4624 5.5-5.5V6Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
);
export const PlayIcon: IconType = (props) => (
  <SVGIcon fill="none" viewBox="0 0 56 56" {...props}>
    <path
      d="M28 0C12.5599 0 0 12.5611 0 28C0 43.4389 12.5599 56 28 56C43.4401 56 56 43.4389 56 28C56 12.5611 43.4401 0 28 0ZM39.1312 28.981L22.7979 39.481C22.6065 39.6051 22.3855 39.6667 22.1667 39.6667C21.9753 39.6667 21.7816 39.6188 21.6085 39.5243C21.2324 39.3192 21 38.9272 21 38.5V17.5C21 17.0728 21.2324 16.6808 21.6085 16.4757C21.9776 16.2729 22.4401 16.2854 22.7979 16.519L39.1312 27.019C39.4639 27.2332 39.6667 27.6035 39.6667 28C39.6667 28.3965 39.4639 28.7667 39.1312 28.981Z"
      fill="currentColor"
    />
  </SVGIcon>
);

export const EmailIcon: IconType = (props) => (
  <SVGIcon fill="none" viewBox="0 0 16 16" {...props}>
    <path
      d="M3.60004 9.59988V14.4999C3.60004 15.1999 4.40004 15.4999 4.90004 14.9999L7.20004 12.2999L11.9 15.7999C12.3 16.0999 12.9 15.8999 13 15.3999L16 0.899877C16.1 0.299877 15.6 -0.100123 15 0.0998775L0.500041 5.89988C4.0561e-05 6.09988 -0.0999594 6.79988 0.300041 7.19988L1.90004 8.39988L6.50004 6.19988C6.90004 5.99988 7.20004 6.49988 6.90004 6.69988L3.60004 9.59988Z"
      fill="currentColor"
    />
  </SVGIcon>
);

export const CrossIcon: IconType = (props) => (
  <SVGIcon fill="none" viewBox="0 0 19 19" {...props}>
    <path
      d="M17.3333 17.3337L1.66663 1.66699"
      stroke="#44475C"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M1.66663 17.3337L17.3333 1.66699"
      stroke="#44475C"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
  </SVGIcon>
);

export const InfoIcon: IconType = (props) => (
  <SVGIcon fill="none" viewBox="0 0 17 17" {...props}>
    <path
      d="M8.50002 15.5832C12.412 15.5832 15.5834 12.4119 15.5834 8.49984C15.5834 4.58782 12.412 1.4165 8.50002 1.4165C4.588 1.4165 1.41669 4.58782 1.41669 8.49984C1.41669 12.4119 4.588 15.5832 8.50002 15.5832Z"
      stroke="#44475C"
      strokeMiterlimit="10"
    />
    <path d="M8.5 13.1042V7.4375" stroke="#44475C" strokeMiterlimit="10" />
    <path
      d="M8.5 6.02051C9.0868 6.02051 9.5625 5.54481 9.5625 4.95801C9.5625 4.37121 9.0868 3.89551 8.5 3.89551C7.9132 3.89551 7.4375 4.37121 7.4375 4.95801C7.4375 5.54481 7.9132 6.02051 8.5 6.02051Z"
      fill="currentColor"
    />
  </SVGIcon>
);

export const BackIcon: IconType = (props) => (
  <SVGIcon fill="none" viewBox="0 0 6 10" {...props}>
    <path d="M5 1L1 5L5 9" stroke="currentColor" />
  </SVGIcon>
);

export const UsaIcon: IconType = (props) => (
  <SVGIcon fill="none" viewBox="0 0 32 22" {...props}>
    <rect fill="#EEF3F8" height="22" width="32" />
    <rect fill="#41479B" height="14" width="18" />
    <rect fill="#DC251C" height="2" width="14" x="18" />
    <rect fill="#DC251C" height="2" width="14" x="18" y="4" />
    <rect fill="#DC251C" height="2" width="14" x="18" y="8" />
    <rect fill="#DC251C" height="2" width="14" x="18" y="12" />
    <rect fill="#DC251C" height="2" width="32" y="16" />
    <rect fill="#DC251C" height="2" width="32" y="20" />
    <rect fill="#C5D0EC" height="2" width="2" x="2" y="2" />
    <rect fill="#C5D0EC" height="2" width="2" x="6" y="2" />
    <rect fill="#C5D0EC" height="2" width="2" x="10" y="2" />
    <rect fill="#C5D0EC" height="2" width="2" x="14" y="2" />
    <rect fill="#C5D0EC" height="2" width="2" x="2" y="6" />
    <rect fill="#C5D0EC" height="2" width="2" x="6" y="6" />
    <rect fill="#C5D0EC" height="2" width="2" x="10" y="6" />
    <rect fill="#C5D0EC" height="2" width="2" x="4" y="8" />
    <rect fill="#C5D0EC" height="2" width="2" x="8" y="8" />
    <rect fill="#C5D0EC" height="2" width="2" x="12" y="8" />
    <rect fill="#C5D0EC" height="2" width="2" x="4" y="4" />
    <rect fill="#C5D0EC" height="2" width="2" x="8" y="4" />
    <rect fill="#C5D0EC" height="2" width="2" x="12" y="4" />
    <rect fill="#C5D0EC" height="2" width="2" x="14" y="6" />
    <rect fill="#C5D0EC" height="2" width="2" x="2" y="10" />
    <rect fill="#C5D0EC" height="2" width="2" x="6" y="10" />
    <rect fill="#C5D0EC" height="2" width="2" x="10" y="10" />
    <rect fill="#C5D0EC" height="2" width="2" x="14" y="10" />
  </SVGIcon>
);

export const LargeCheckIcon: IconType = (props) => (
  <SVGIcon fill="none" viewBox="0 0 48 48" {...props}>
    <path
      d="M24 0C19.2533 0 14.6131 1.40758 10.6663 4.04473C6.71954 6.68188 3.6434 10.4302 1.8269 14.8156C0.0103988 19.201 -0.464881 24.0266 0.461164 28.6822C1.38721 33.3377 3.67299 37.6141 7.02945 40.9706C10.3859 44.327 14.6623 46.6128 19.3178 47.5388C23.9734 48.4649 28.799 47.9896 33.1844 46.1731C37.5698 44.3566 41.3181 41.2805 43.9553 37.3337C46.5924 33.3869 48 28.7467 48 24C47.9932 17.6369 45.4624 11.5364 40.963 7.03695C36.4636 2.53756 30.3631 0.00680668 24 0ZM33.7834 19.212L21.7834 31.212C21.462 31.5334 21.026 31.7139 20.5714 31.7139C20.1169 31.7139 19.6809 31.5334 19.3594 31.212L14.2166 26.0691C13.9043 25.7458 13.7315 25.3128 13.7354 24.8633C13.7393 24.4138 13.9196 23.9839 14.2375 23.666C14.5553 23.3482 14.9853 23.1679 15.4347 23.164C15.8842 23.1601 16.3173 23.3329 16.6406 23.6451L20.5714 27.576L31.3594 16.788C31.6827 16.4757 32.1158 16.3029 32.5653 16.3068C33.0147 16.3107 33.4447 16.491 33.7626 16.8089C34.0804 17.1267 34.2607 17.5567 34.2646 18.0062C34.2685 18.4556 34.0957 18.8887 33.7834 19.212Z"
      fill="currentColor"
    />
  </SVGIcon>
);

export const AngleIcon: IconType = (props) => (
  <SVGIcon fill="none" viewBox="0 0 8 6" {...props}>
    <path
      d="M7.33346 1.11378C7.20855 0.989616 7.03959 0.919922 6.86346 0.919922C6.68734 0.919922 6.51837 0.989616 6.39346 1.11378L4.00013 3.47378L1.64013 1.11378C1.51522 0.989616 1.34625 0.919922 1.17013 0.919922C0.994005 0.919922 0.825037 0.989616 0.700129 1.11378C0.637643 1.17576 0.588047 1.24949 0.554201 1.33073C0.520355 1.41197 0.50293 1.49911 0.50293 1.58712C0.50293 1.67512 0.520355 1.76226 0.554201 1.8435C0.588047 1.92474 0.637643 1.99847 0.700129 2.06045L3.52679 4.88712C3.58877 4.9496 3.6625 4.9992 3.74374 5.03304C3.82498 5.06689 3.91212 5.08432 4.00013 5.08432C4.08814 5.08432 4.17527 5.06689 4.25651 5.03304C4.33775 4.9992 4.41149 4.9496 4.47346 4.88712L7.33346 2.06045C7.39595 1.99847 7.44554 1.92474 7.47939 1.8435C7.51323 1.76226 7.53066 1.67512 7.53066 1.58712C7.53066 1.49911 7.51323 1.41197 7.47939 1.33073C7.44554 1.24949 7.39595 1.17576 7.33346 1.11378Z"
      fill="currentColor"
    />
  </SVGIcon>
);
