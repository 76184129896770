import { Entry } from "contentful";
import {
  IBuildsFields,
  IJetsonOnePerkListFields,
  ITeamListFields,
} from "types";

const space = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID;
const accessToken = process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN;
const currentEnv = process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT;

const client = require("contentful").createClient({
  space: space,
  accessToken: accessToken,
  environment: currentEnv,
});

export async function fetchNewsArticles() {
  const entries = await client.getEntries({
    content_type: "articleV3",
    order: "-fields.dateOfPublish",
  });
  if (entries.items) return entries.items;
  console.warn(`Error getting Entries.`);
}

export async function fetchHeaderPress() {
  const entries = await client.getEntries({
    content_type: "pressItem",
    limit: 1,
    order: "-sys.createdAt",
  });
  if (entries.items) return entries.items;
  console.warn(`Error getting Entries.`);
}

export async function fetchMainTeam() {
  const entries = await client.getEntries({
    content_type: "teamV3",
    limit: 4,
    "fields.category": "Main team",
    order: "sys.createdAt",
  });
  if (entries.items) return entries.items;
  console.warn(`Error getting Entries.`);
}

export async function fetchHeadTeam() {
  const entries = await client.getEntries({
    content_type: "teamV3",
    limit: 4,
    "fields.category": "Head team",
    order: "sys.createdAt",
  });
  if (entries.items) return entries.items;
  console.warn(`Error getting Entries.`);
}

export async function fetchRestTeam() {
  const entries = await client.getEntries({
    content_type: "teamV3",
    "fields.category": "Subteam",
    order: "sys.updatedAt",
  });
  if (entries.items) return entries.items;
  console.warn(`Error getting Entries.`);
}

export async function fetchNews(articleSlug: string) {
  try {
    const res = await client.getEntries({
      content_type: "articleV3",
      "fields.slug": articleSlug,
      limit: 1,
    });
    const entry = res.items[0];
    return {
      ...entry.fields,
      createdAt: entry.sys.createdAt,
      updatedAt: entry.sys.updatedAt,
    };
  } catch (err) {
    console.error("Error while getting last orders");
  }
}
export async function fetchJobs(careerSlug: string) {
  try {
    const res = await client.getEntries({
      content_type: "jobsV3",
      "fields.slug": careerSlug,
      limit: 1,
    });
    const entry = res.items[0];
    return {
      ...entry.fields,
      createdAt: entry.sys.createdAt,
      updatedAt: entry.sys.updatedAt,
    };
  } catch (err) {
    console.error("Error while getting last orders");
  }
}

export async function fetchPerks(skip?: number): Promise<{
  perksList: IJetsonOnePerkListFields[];
}> {
  try {
    const entries = await client.getEntries({
      content_type: "jetsonOnePerkList",
      limit: 999,
      skip: skip ?? 0,
      order: "-sys.createdAt",
    });
    const perksList = await entries.items.map(
      (p: Entry<IJetsonOnePerkListFields>) => {
        return p.fields;
      }
    );
    return { perksList };
  } catch (err) {
    console.error("Error while getting last orders");
    return { perksList: [] };
  }
}

export async function fetchBuildList(skip?: number): Promise<{
  buildList: IBuildsFields[];
}> {
  try {
    const entries = await client.getEntries({
      content_type: "builds",
      limit: 999,
      skip: skip ?? 0,
      order: "-sys.createdAt",
    });
    const buildList = await entries.items.map((p: Entry<IBuildsFields>) => {
      return p.fields;
    });
    return { buildList };
  } catch (err) {
    console.error("Error while getting last orders");
    return { buildList: [] };
  }
}

export async function fetchTeamList(skip?: number): Promise<{
  teamList: ITeamListFields[];
}> {
  try {
    const entries = await client.getEntries({
      content_type: "teamList",
      limit: 999,
      skip: skip ?? 0,
      order: "-sys.updatedAt",
    });
    const teamList = await entries.items.map((p: Entry<ITeamListFields>) => {
      return p.fields;
    });
    return { teamList };
  } catch (err) {
    console.error("Error while getting last orders");
    return { teamList: [] };
  }
}

export async function fetchHeaderArticle() {
  const entries = await client.getEntries({
    content_type: "articleV3",
    limit: 1,
    order: "-fields.dateOfPublish",
  });
  if (entries.items) return entries.items;
  console.warn(`Error getting Entries.`);
}

export async function getContentfulEntries<TEntry>(
  contentType: string,
  order?: string,
  limit?: number,
  isMainPageTeam?: boolean,
  skip?: number
): Promise<TEntry[]> {
  try {
    const response = await client.getEntries({
      content_type: contentType,
      limit: limit,
      skip: skip ?? 0,
      order: order,
      "fields.mainPageTeam": isMainPageTeam,
    });
    const entries = await response.items.map((p: Entry<TEntry>) => {
      return p.fields;
    });
    return entries;
  } catch (err) {
    console.error("Error while getting last orders");
    return [];
  }
}
