import { PaymentMethodsEnum } from "@types";
import { Radio } from "components";
import { mergeClasses } from "utils";

interface PaymentMethod {
  name: PaymentMethodsEnum;
  title: string;
  description?: string;
}

const paymentMethods: PaymentMethod[] = [
  {
    name: PaymentMethodsEnum.WireTransfer,
    title: "Wire transfer",
    description:
      "Our Sales Representative will contact you as soon as possible to discuss all payment and delivery details.",
  },
  {
    name: PaymentMethodsEnum.Card,
    title: "Credit Card",
  },
];

const PaymentMethodItem: React.FC<
  PaymentMethod & {
    onClick: () => void;
    checked: boolean;
  }
> = ({ name, title, description, onClick, checked }) => {
  return (
    <li
      className={mergeClasses(
        "border border-gray-300 p-4 cursor-pointer transition-all",
        checked ? "bg-white" : "hover:bg-[#fdfdfd]"
      )}
      onClick={onClick}
    >
      <div className="flex items-center">
        <Radio checked={checked} name="paymentMethod" value={name} />
        <p className="font-medium leading-5 ml-4 transition-all">{title}</p>
      </div>
      {description && (
        <p
          className={mergeClasses(
            "text-sm leading-4 transition-all overflow-hidden",
            checked ? "mt-4 max-h-28" : "max-h-0"
          )}
        >
          {description}
        </p>
      )}
    </li>
  );
};

interface PaymentMethodSelectProps {
  onChange: (newValue: PaymentMethodsEnum) => void;
  value: PaymentMethodsEnum;
}

const LongPaymentMethodSelect: React.FC<PaymentMethodSelectProps> = ({
  value,
  onChange,
}) => {
  return (
    <ul className="space-y-4">
      {paymentMethods.map((method, index) => (
        <PaymentMethodItem
          checked={value === method.name}
          onClick={() => onChange(method.name)}
          {...method}
          key={`paymentMethod_${index}`}
        />
      ))}
    </ul>
  );
};

export default LongPaymentMethodSelect;
