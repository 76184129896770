import { ITechSpecificationV3Fields } from "types";

const TechSpecificationSection: React.FC<{
  techSpecification: ITechSpecificationV3Fields[];
}> = ({ techSpecification }) => {
  return (
    <section className="bg-[#0C0C0E] text-white py-section">
      <div className="container flex flex-row justify-center">
        <div className="col-span-3 lg:col-span-2 text-center">
          <h4 className="max-w-5xl text-5xl lg:text-8xl">Tech Specs</h4>
          <div className="border-white border-[1px] lg:mt-24 mt-14 lg:max-w-[50vw] mx-auto ">
            {techSpecification.map((spec, index) => (
              <div
                className="flex lg:gap-52 lg:flex-row lg:py-[10px] lg:px-[20px] lg:justify-between lg:items-center items-start flex-col py-[5px] px-[15px] border-b-[1px] border-white last-of-type:border-none"
                key={index}
              >
                <span className="lg:text-base text-[13px] uppercase font-bold text-left">
                  {spec.title}
                </span>
                <span className="lg:text-[20px] text-base font-bold uppercase lg:text-right text-left">
                  {spec.value}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
export default TechSpecificationSection;
