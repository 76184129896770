import { Modal } from "components";
import { ReactNode } from "react";

interface FeesModalProps {
  isModalOpen: boolean;
  setModalClose: () => void;
  description: ReactNode;
}

const FeesModal: React.FC<FeesModalProps> = ({
  isModalOpen,
  setModalClose,
  description,
}) => {
  return (
    <Modal
      className="max-w-[888px] lg:h-[386px] h-[768px]"
      closeActions={setModalClose}
      isModalOpen={isModalOpen}
    >
      <div className="max-w-[728px]">
        <h3 className="font-semibold text-2xl mb-[51px]">Fees & taxes</h3>
        <p className="text-xl">{description}</p>
      </div>
    </Modal>
  );
};
export default FeesModal;
