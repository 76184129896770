import { Button } from "components";
import { ArrowIcon } from "icons";
import Image from "next/image";
import Link from "next/link";
import { ITeamV3Fields } from "types";

const TeamMember: React.FC<ITeamV3Fields> = ({ name, position, avatar }) => {
  return (
    <div className="flex items-center max-w-[360px] lg:max-w-[390px] w-full p-4">
      <div className="relative rounded-full overflow-hidden min-w-[88px] min-h-[88px]">
        <Image alt="" layout="fill" src={`https:${avatar.fields.file.url}`} />
      </div>
      <div className="ml-6">
        <p className="mb-1 lg:mb-2 text-xl lg:text-2xl !leading-[1.1]">
          {name}
        </p>
        <p className="text-accent font-medium text-xs lg:text-[13px] uppercase">
          {position}
        </p>
        <p>{}</p>
      </div>
    </div>
  );
};

const RestTeamSection: React.FC<{
  team: ITeamV3Fields[];
}> = ({ team }) => {
  return (
    <section className="bg-white lg:-mt-[44px]">
      <div className="container py-section xl:box-content">
        <h2 className="heading-2 text-center mb-14">The Team</h2>
        <div className="flex flex-wrap flex-row gap-0 lg:gap-[30px] justify-center">
          {team.map((member, index) => (
            <TeamMember key={`teamMember_${index}`} {...member} />
          ))}
        </div>
        <div className="mt-10 lg:mt-16">
          <Link href="team" passHref>
            <a>
              <Button
                className="!justify-between w-[202px] mx-auto button-primary md:w-auto !px-8 !py-4"
                label="meet our team"
                rightIcon={ArrowIcon}
                rightIconClassName="text-accent ml-5 !w-6 !h-6"
              />
            </a>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default RestTeamSection;
