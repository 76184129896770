import { Button } from "components";
import { ArrowIcon } from "icons";
import Link from "next/link";
import { IBuildSlotFields } from "types";

const OrderItem: React.FC<IBuildSlotFields> = ({ number, location }) => (
  <li className="text-white lg:flex flex-col justify-between items-start">
    <p className="text-2xl lg:text-[32px] mb-4 font-medium">
      Chassis #{number}
    </p>
    <span className="sm:flex items-center">
      {location ? (
        <p className="text-lg lg:text-2xl mr-2 lg:mr-4">
          Reserved for a client in {location}
        </p>
      ) : null}
    </span>
  </li>
);

const OrderYourOwnSection: React.FC<{
  lastOrders: IBuildSlotFields[];
}> = ({ lastOrders }) => {
  return (
    <section
      className="py-section bg-right bg-no-repeat bg-contain lg:bg-[length:1450px_1063px]"
      style={{
        background: "rgb(45,45,47)",
        backgroundImage: "url(assets/images/orders-list-bg.png)",
      }}
    >
      <div className="container">
        <h2 className="heading-2 text-white mb-10 lg:mb-8 -mx-1">
          Order your own
        </h2>
        <div className="bg-black p-6 sm:px-10 sm:py-8 max-w-[950px] rounded-[48px] mb-12 text-center sm:text-left">
          <p className="text-[#B9B9B9] text-[13px] uppercase font-medium">
            The entire 2022 production is sold out
          </p>
          <p className="text-white text-[24px] lg:text-[32px] font-medium">
            We are accepting orders for 2023 delivery
          </p>
        </div>
        {!!lastOrders?.length ? (
          <ul className="max-w-[905px] space-y-[72px]">
            {lastOrders.map((order) => (
              <OrderItem key={order.number} {...order} />
            ))}
          </ul>
        ) : (
          <p className="text-white text-lg">No orders found</p>
        )}
        <div className="flex flex-col items-center">
          <a>
            <Link href="order" passHref>
              <Button
                className="!justify-between mt-8 lg:mt-20 button-secondary !px-8 !py-4 lg:w-[217px]"
                label="Order your own"
                rightIcon={ArrowIcon}
                rightIconClassName="text-accent ml-5 !w-6 !h-6"
              />
            </Link>
          </a>
        </div>
      </div>
    </section>
  );
};

export default OrderYourOwnSection;
