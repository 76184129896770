import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { Breadcrumbs, Button } from "components";
import { format } from "date-fns";
import { ArrowIcon } from "icons";
import Image from "next/image";
import Link from "next/link";
import { INews } from "types";
import { truncateText } from "utils";

const NewsHeaderSection: React.FC<{
  articles: INews[];
}> = ({ articles }) => {
  return (
    <section className="bg-white lg:mt-0 md:-mt-4">
      <div className="container pb-section">
        <Breadcrumbs className="mb-6 lg:mb-16" />
        {articles.map((article, index) => (
          <div className="flex flex-col lg:flex-row items-center" key={index}>
            <div className="rounded-[32px] w-full max-w-[1062px] h-[498px] lg:h-[598px] relative">
              <Link href={`/news/${article.slug}`} passHref>
                <a>
                  <Image
                    alt={article.mainImage?.fields.file.fileName}
                    className="w-full h-full !relative object-cover rounded-[16px] lg:rounded-[32px]"
                    layout="fill"
                    src={`https:${article.mainImage?.fields.file.url}`}
                  />
                </a>
              </Link>
            </div>
            <div className="lg:pl-[64px] flex flex-col w-full max-w-[636px]">
              <div className="flex flex-row space-x-[16px] text-[13px] items-center pt-6 lg:pt-0">
                <h4 className="uppercase text-accent leading-[15px]">
                  Featured news
                </h4>
                <span className="">&middot;</span>
                <p>
                  {format(
                    new Date(article.createdAt),
                    "yyyy MMM dd"
                  ).toUpperCase()}
                </p>
              </div>
              <div className="pt-[15px] lg:text-left text-center">
                <Link href={`/news/${article.slug}`} passHref>
                  <a>
                    <h2 className="text-[56px] leading-[56px] hover:underline">
                      {article.title}
                    </h2>
                  </a>
                </Link>
                <p className="text-[18px] pt-[32px] leading-[27px]">
                  {article.shortContent
                    ? article.shortContent
                    : truncateText(
                        documentToPlainTextString(article.content),
                        250
                      )}
                  &nbsp;
                </p>
                <Link href={`/news/${article.slug}`} passHref>
                  <a>
                    <Button
                      className="!justify-between w-full mt-8 lg:mt-16 button-primary md:w-auto !px-8 !py-4 hover:underline"
                      label="read more"
                      rightIcon={ArrowIcon}
                      rightIconClassName="text-accent ml-5 !w-6 !h-6"
                    />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
export default NewsHeaderSection;
