import Image from "next/image";
import { ITeamHeaderSectionFields } from "types";

const TeamHeaderSection: React.FC<{
  headerSection: ITeamHeaderSectionFields[];
}> = ({ headerSection }) => {
  return (
    <section>
      <div className="container pb-section">
        {headerSection.map((header, index) => (
          <div
            className="relative w-full h-96 md:h-[460px] lg:h-[640px] bg-black overflow-hidden rounded-[48px] flex justify-center items-center"
            key={index}
          >
            <Image
              alt="team"
              layout="fill"
              objectFit="cover"
              priority
              src="/assets/images/team.png"
            />
            <div className="text-center absolute text-white">
              <h1 className="heading-1 mb-2 lg:mb-4">{header.title}</h1>
              <h2 className="subheading-1 max-w-[721px] mx-4">
                {header.subtitle}
              </h2>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TeamHeaderSection;
