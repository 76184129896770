import { Button } from "components";
import { ArrowIcon } from "icons";
import Image from "next/image";
import Link from "next/link";
import { IJetsonOnePerkListFields, IJetsonOneSectionFields } from "types";

const JetsonOneSection: React.FC<{
  perksList: IJetsonOnePerkListFields[];
  jetsonOne: IJetsonOneSectionFields[];
}> = ({ perksList, jetsonOne }) => {
  return (
    <section
      className="text-center bg-no-repeat bg-bottom h-[1023px] lg:h-[1800px] 4xl:h-[2115px] 4xl:bg-[length:2820px] bg-white bg-[length:920px] lg:bg-[length:1920px] overflow-hidden"
      style={{
        backgroundImage: "url(/assets/images/background-banner.jpg)",
      }}
    >
      {jetsonOne.map((jetson, index) => (
        <div
          className="container flex justify-center pt-section flex-col"
          key={index}
        >
          <div className="flex flex-col items-center text-center text-black">
            <h2 className="heading-2">{jetson.title}</h2>
            <p className="subheading-1 pt-[16px] max-w-[576px]">
              {jetson.description}
            </p>
          </div>
          <div className="pt-[56px] lg:pt-[64px] ml-[-35vw] sm:ml-auto w-[150vw] sm:w-full flex flex-col items-center">
            <Image
              alt="jetson one"
              height={475}
              priority
              src="/assets/images/jetson-one.png"
              width={1183}
            />
          </div>
          <div className="pb-[40px] mx-auto pt-section">
            {perksList.map(({ jetsonOnePerks }, i) => (
              <div
                className="flex flex-row items-end justify-between md:justify-center space-x-[20px] lg:space-x-[130px] pt-[24px] lg:pt-0"
                key={i}
              >
                {jetsonOnePerks?.map(({ fields }) =>
                  fields.title ? (
                    <div key={index}>
                      <div className="flex flex-col">
                        <p className="text-gray text-[11px] lg:text-[13px] mb-[-10px]">
                          {fields.title}
                        </p>
                        <div className="flex flex-row">
                          <p className="text-black text-[40px] lg:text-[64px] font-semibold">
                            {fields.value}
                          </p>
                          <p className="self-start pt-4 lg:pt-5 pl-[8px] text-[11px] lg:text-xl">
                            {fields.unit}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null
                )}
              </div>
            ))}
          </div>
          <Link href={jetson.buttonUrl} passHref>
            <a className="lg:block hidden">
              <Button
                className="!justify-between w-[189px] mx-auto button-primary md:w-auto p-5"
                label={jetson.buttonText}
                rightIcon={ArrowIcon}
                rightIconClassName="text-accent ml-9 !w-6 !h-6 rotate-90"
              />
            </a>
          </Link>
        </div>
      ))}
    </section>
  );
};

export default JetsonOneSection;
