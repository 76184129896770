import { Button } from "components";
import { ArrowIcon } from "icons";
import Image from "next/image";
import { IPress } from "types";

const PressHeaderSection: React.FC<{
  featured: IPress[];
}> = ({ featured }) => {
  return (
    <section className="lg:mt-0 md:-mt-4">
      <div className="container pb-section">
        {featured.map((featuredItem, index) => (
          <div
            className="flex flex-col-reverse lg:flex-row items-center"
            key={index}
          >
            <div className="flex flex-col w-full max-w-[636px]">
              <div className="pt-[15px] lg:text-left text-center">
                <a
                  href={featuredItem.articleUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  <h2 className="text-[56px] leading-[56px] hover:underline">
                    {featuredItem.title}
                  </h2>
                  <div className="relative h-4 lg:h-12 pt-6 flex w-24 justify-start mt-6">
                    <Image
                      alt="brand logo"
                      layout="fill"
                      objectFit="contain"
                      src={`https:${featuredItem.brandLogo.fields.file.url}`}
                    />
                  </div>
                </a>
                <a
                  href={featuredItem.articleUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button
                    className="!justify-between w-full mt-8 lg:mt-16 button-primary md:w-auto !px-8 !py-4 hover:underline"
                    label="read more"
                    rightIcon={ArrowIcon}
                    rightIconClassName="text-accent ml-5 !w-6 !h-6"
                  />
                </a>
              </div>
            </div>
            <div className="rounded-[32px] w-full max-w-[1062px] h-[498px] lg:h-[598px] relative">
              <a
                href={featuredItem.articleUrl}
                rel="noreferrer"
                target="_blank"
              >
                <Image
                  alt={featuredItem.thumbnail.fields.file.fileName}
                  className="w-full h-full !relative object-cover rounded-[16px] lg:rounded-[32px]"
                  layout="fill"
                  src={`https:${featuredItem.thumbnail.fields.file.url}`}
                />
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
export default PressHeaderSection;
