import { Button } from "components";
import { ArrowIcon } from "icons";
import Image from "next/image";
import Link from "next/link";
import { IMeetJetsonOneSectionFields } from "types";

const MeetJetsonOneSection: React.FC<{
  meetJetsoOneSection: IMeetJetsonOneSectionFields[];
}> = ({ meetJetsoOneSection }) => {
  return (
    <section className="py-section bg-white">
      {meetJetsoOneSection.map((meetJetson, index) => (
        <div
          className="container flex flex-col items-center relative"
          key={index}
        >
          <div className="flex flex-col-reverse lg:flex-row xl:gap-[81px] items-center mb-10 lg:mb-0">
            <Image
              alt="jetson one"
              className="rounded-[24px] lg:rounded-[48px] bg-black"
              height={692}
              priority
              src="/assets/images/meet-jetson-1.png"
              width={530}
            />
            <h2 className="heading-2 text-center lg:max-w-[660px] mb-8 lg:mb-0">
              {meetJetson.title}
            </h2>
          </div>
          <div className="flex flex-col lg:flex-row items-center w-full lg:-mt-28 z-10 lg:h-[640px]">
            <div className="max-w-[547px]">
              <p className="subheading-1">{meetJetson.firstText}</p>
              <p className="subheading-1 mt-5">{meetJetson.secondText}</p>
            </div>

            <div className="py-6 lg:py-[52px] max-w-[1086px]">
              <Image
                alt="jetson one"
                height={560}
                priority
                src="/assets/images/meet-jetson-2.png"
                width={1086}
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-center lg:-mt-36 z-10 lg:h-[640px]">
            <div className="py-6 lg:py-[52px] xl:-ml-20">
              <Image
                alt="jetson one"
                height={559}
                priority
                src="/assets/images/meet-jetson-3.png"
                width={1155}
              />
            </div>
            <div></div>
            <div className="max-w-[547px]">
              <p className="subheading-1">{meetJetson.thirdText}</p>
              <p className="subheading-1 mt-5">{meetJetson.fourthText}</p>
              <Link href={meetJetson.buttonUrl} passHref>
                <a>
                  <Button
                    className="!justify-between mx-auto button-primary md:w-auto !px-8 !py-4 mt-16"
                    label={meetJetson.buttonText}
                    rightIcon={ArrowIcon}
                    rightIconClassName="text-accent ml-5 !w-6 !h-6 rotate-90"
                  />
                </a>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};
export default MeetJetsonOneSection;
