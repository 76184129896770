import { mergeClasses } from "utils";

const Loader: React.FC<{ className?: string }> = ({ className = "" }) => {
  return (
    <div
      className={mergeClasses(
        "loader absolute left-0 right-0 mx-auto text-accent",
        className
      )}
    >
      <div />
      <div />
    </div>
  );
};

export default Loader;
