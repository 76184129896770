import HotJar from "@hotjar/browser";
import { PageLayout } from "components";
import type { AppProps } from "next/app";
import App from "next/app";
import { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { IFooterFields, INavigationFields } from "types";
import { getContentfulEntries } from "utils/contentful-api";
import "../styles/globals.css";

function MyApp({
  Component,
  pageProps,
  footer,
  navigation,
}: AppProps & { footer: any; navigation: any }) {
  useEffect(() => {
    const hotJarSiteId = Number(
      process.env.NEXT_PUBLIC_CONTENTFUL_HOTJAR_SITE_ID
    );
    if (hotJarSiteId) {
      HotJar.init(hotJarSiteId, 6);
    }
  });

  return (
    <PageLayout footer={footer} navigation={navigation}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
        useEnterprise
      >
        <Component {...pageProps} />
      </GoogleReCaptchaProvider>
    </PageLayout>
  );
}

export default MyApp;

MyApp.getInitialProps = async (context: any) => {
  const pageProps = await App.getInitialProps(context);

  const footer = await getContentfulEntries<IFooterFields>("footer");

  const navigation = await getContentfulEntries<INavigationFields>(
    "navigation"
  );

  return { ...pageProps, footer, navigation };
};
